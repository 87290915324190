import React, { useState, useCallback } from 'react'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent, {
  DialogContentProps,
} from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import MobileStepper, {
  MobileStepperProps,
} from '@material-ui/core/MobileStepper'
import { createStyles, makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

export interface ModalWrapperProps {
  dialogClass?: string | null
  DialogProps?: Partial<DialogProps>
  ContentProps?: Partial<DialogContentProps>
  callToActionProps?: ButtonProps
  dotsStepperProps?: Partial<MobileStepperProps>
  heroImageURL?: string
  showButton?: boolean
  wideDialog?: boolean
  onBackClick?: () => void
  onClose?: () => void | Promise<void>
}

export type ModalProps = Partial<
  Pick<ModalWrapperProps, 'onClose' | 'callToActionProps'>
>

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      padding: 0,
      paddingTop: '1em',
      position: 'absolute',
      width: 'calc(100% - 25px)',
      paddingLeft: '40px',
    },
    // @TODO Alex Brown, this is looking bad on mobile
    fullScreenHeader: {
      borderBottom: '1px solid #e4e8f0',
      paddingTop: '9px',
      paddingBottom: '9px',

      '& > img': {
        height: '19px',
        verticalAlign: 'middle',
      },
    },
    backButton: {
      marginTop: '3px',
    },
    backButtonIcon: {
      marginRight: 0,
    },
    closeIcon: {
      position: 'absolute',
      right: 0,
      zIndex: 2,
    },
    content: {
      textAlign: 'center',

      '& > img': {
        marginBottom: '30px',
        maxWidth: '204px',
      },

      '& > h2': {
        marginBottom: '0.5em',
      },
    },
    ctaButton: {
      width: '100%',
      borderRadius: '32px',
    },
    stepper: {
      marginTop: '12.5px',
      marginBottom: '-30px',
    },
    [theme.breakpoints.down('xs')]: {
      header: {
        position: 'static',
      },
      closeIcon: {
        position: 'static',
      },
    },
  })
)

const ModalWrapper: React.FC<ModalWrapperProps> = ({
  children,
  dialogClass = null,
  DialogProps,
  callToActionProps,
  dotsStepperProps,
  heroImageURL,
  showButton = true,
  wideDialog = false,
  onBackClick,
  onClose,
  ContentProps = {},
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const theme = useTheme()
  const fullScreenModal = useMediaQuery(theme.breakpoints.down('xs'))
  const { t } = useTranslation()
  const showBackButton = onBackClick && (dotsStepperProps?.activeStep || 0) > 0

  const handleClose = useCallback(async () => {
    if (onClose) {
      await onClose()
    }

    setOpen(false)
  }, [onClose])

  return (
    <Dialog
      classes={{ paper: dialogClass ? dialogClass : '' }}
      open={open}
      fullScreen={fullScreenModal}
      {...DialogProps}
      onClose={handleClose}
      maxWidth={wideDialog ? 'md' : 'sm'}
    >
      <DialogTitle
        disableTypography
        className={clsx({
          [classes.header]: !fullScreenModal,
          [classes.fullScreenHeader]: fullScreenModal,
        })}
      >
        {showBackButton && (
          <Button
            onClick={onBackClick}
            startIcon={<ChevronLeftIcon />}
            color="secondary"
            classes={{
              startIcon: classes.backButtonIcon,
            }}
            className={classes.backButton}
          >
            {t('components.Modals.Wrapper.back')}
          </Button>
        )}
        <IconButton
          onClick={handleClose}
          className={classes.closeIcon}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent
        {...ContentProps}
        className={clsx(classes.content, ContentProps.className)}
      >
        {heroImageURL && <img alt="" src={heroImageURL} />}
        {children}
      </DialogContent>

      <DialogActions>
        {showButton && (
          <Button
            className={classes.ctaButton}
            variant="contained"
            color="primary"
            {...callToActionProps}
          />
        )}
        {dotsStepperProps && (
          <MobileStepper
            variant="dots"
            steps={6}
            position="static"
            backButton={null}
            nextButton={null}
            {...dotsStepperProps}
            className={clsx(classes.stepper, dotsStepperProps.className)}
          />
        )}
      </DialogActions>
    </Dialog>
  )
}

export default ModalWrapper
