import { ValidationMessageKeys as UserValidationMessageKeys } from 'store/user/validation'
import {
  ItemStatusTypes,
  ItemTypes,
  PreparednessStatus,
} from 'store/items/types'
import { OrderStatusTypes, OrderTypes } from 'store/orders/types'
import { NotificationTypes } from 'store/notifications/types'
import { Bundles } from 'store/upsells/types'

export default {
  // Please try to nest your translation keys to somewhat match the path to the
  // component.
  translation: {
    components: {
      Auth: {
        Login: {
          back: 'Back to site',
          header: 'Login',
          dontHaveAnAccount:
            "Don't have an account? View <a href={{packageURL}}>our packages</a>",
        },
        Reset: {
          button: 'Send reset link',
          input: 'Email Address',
          instructions:
            'Please enter your email address. We will send you an email to reset your password.',
          title: 'Reset Password',
          success: 'Check your email for the password reset link.',
          failure: 'Could not request password reset: {{error}}',
        },
        SignUpForm: {
          title: 'Finish signing up',
          subtitle: 'to continue to {{brand}}',
          emailAddressLabel: 'Email address',
          passwordLabel: 'Password',
          continueBtn: 'Continue',
          explanation:
            'To receive your documents and check on the status of your order, create a password.',
          explanationTopInterview:
            'Your {{brand}} account is the best way to track, schedule and collaborate with your Expert.',
          validation: {
            strength: 'Password strength: {{strength}}',
            uppercase: 'At least 1 upper case letter',
            lowercase: 'At least 1 lower case letter',
            length: 'At least 8 characters',
            tooLong: 'Less than 72 characters',
            number: 'Contains a number',
            symbol: 'Contains a symbol',
            invalidEmail: 'Email is invalid',
          },
          submissionFailure: 'Sign up failed, please try again',
        },
      },
      Checkout: {
        button: 'Checkout',
        save: 'You save',
        subtotal: 'Subtotal',
        title: 'Your Cart',
        total: 'Your total',
        empty: 'Your cart is empty',
        shopNow: 'Shop now',
      },
      Content: {
        articles: [
          {
            imgAlt: 'Writing process',
            tag: 'Company',
            title: 'What to expect when you work with {{brand}}',
          },
          {
            imgAlt: 'Calendar',
            tag: 'Company',
            title: 'Getting to know our writers',
          },
          {
            imgAlt: 'Messaging',
            tag: 'How To',
            title: 'Setting the stage for a successful search',
          },
          {
            imgAlt: 'Questionnaire',
            tag: 'How To',
            title: 'Collaborating with Your Writer',
          },
          {
            imgAlt: 'Questionnaire',
            tag: 'Company',
            title: 'Taking the next step',
          },
        ],
        upsellButton: 'Add to order',
        widgetTitle: 'Order Success Guide',
      },
      Faqs: {
        title: 'FAQS',
        viewAll: 'View all',
        careerJourney: [
          {
            question:
              'I would like to add another item to my order. Will it affect my order due date?',
            answer:
              'When you add an item to your order,  the due date for your original order will remain unchanged. Any additional items purchased will have new due dates. You will receive your original order first, followed by any additional items you may have purchased.',
          },
          {
            question: 'What is a phone consultation?',
            answer:
              'A phone consultation allows you to speak with your expert on the phone about your documents to discuss the strategy and share changes/additions you might have, after you have received your first draft.',
          },
          {
            question: 'What is ResumeRabbit?',
            answer:
              'ResumeRabbit is a resume posting service that allows you to search smarter, not harder. ResumeRabbit’s express resume-posting features allow you to post your resume to 50 job sites at once, so you can focus on finding your dream job.',
          },
          {
            question: 'What is TopInterview?',
            answer:
              'TopInterview is a coaching service designed to help you prepare for job interviews. The core of the interview coaching experience is 1-on-1 sessions with an interview coach. These sessions are conducted over video conference and are tailored to your needs as a jobseeker, with your industry and stage in the job search in mind.',
          },
          {
            question: 'How do I contact my expert for a phone consultation?',
            answer:
              'Your expert will reach out to you to schedule a time for your consultation. Once your consultation is scheduled, your writer will call you at the phone number you provide. Make sure you are available and are in a quiet place so that you can discuss your goals. Your writer may have more than one call scheduled so  being available and on time is paramount.',
          },
        ],
        careerJourneyTitle: 'Your Career Journey',
        gettingToKnow: [
          {
            question: 'Why did I have to submit a questionnaire?',
            answer:
              'The questionnaire is a vital part of the writing process. The answers help our experts learn about you, your skills, and your experience outside of what is included in your current resume or in other documents provided.',
          },
          {
            question: 'Why did I have to create a {{brand}} account?',
            answer:
              'Creating an account is the easiest way to communicate with your writer and see the documents your writer is working on for you. Creating an account is a quick and secure process, and all of your information is protected.',
          },
          {
            question:
              'Can I speak to my writer instead of answering the questionnaire?',
            answer:
              'The questionnaire is what allows us to match you with the best writer for you. Once you have been matched with a writer, you can schedule a call with him or her at an additional cost. If you would like to speak to your writer on the phone, you can add this service to your order on the <a href="/upgrade">Upgrade</a> page.',
          },
          {
            question: 'Can I receive my documents faster?',
            answer:
              'With the 48-hour rush option, you’ll get the first draft of your resume within 48 hours! The sooner you receive your first draft the sooner you can collaborate with your writer and maximize the revision period.',
          },
        ],
        gettingToKnowTitle: 'Getting to Know You',
        professionalDocuments: [
          {
            question: 'When will I get my resume?',
            answer:
              'You can expect to receive your professional documents from us on {{dueDate}}. Check your order messages to ensure your writer has everything they need to craft you winning documents.',
          },
          {
            question: 'When will I get my LinkedIn rewrite?',
            answer:
              'If you have ordered a resume, your writer will work on your resume first to ensure you are happy with how your skills and experience are presented. You should expect to receive your Linkedin profile once you and your writer are satisfied with your resume.',
          },
          {
            question: 'How do I download my documents?',
            answer:
              'On the ‘Messages’ page you will see a button on each of your documents that says “Download”. If you click this, you can download your document in MS Word or PDF formats.',
          },
          {
            question: 'How do I upload my documents?',
            answer:
              "Uploading documents and attachments couldn't be easier. If you go to your “Messages”, page there is a small paperclip icon on the bottom left of your screen. If you click this, you will be asked to upload a document you can then send to your expert.",
          },
          {
            question: 'How do I approve my order?',
            answer:
              'There are a few places you can “approve” your order. <br>On the “Messages” page under you listed documents on the right side of the screen you will see the “Approve” button. The second place you can “Approve” your order is when you are viewing your documents on the messages page. You will see the “Approve” button located to the top right of your screen.',
          },
        ],
        professionalDocumentsTitle: 'Your Professional Documents',
        troubleshooting: [
          {
            question: 'Why has my writer stopped responding to me?',
            answer:
              'If you haven’t heard from your writer, in most cases this means they are hard at work for you: researching your industry for the latest trends, analyzing your questionnaire, and applying change requests to your documents.If you have not heard from your writer in more than 24 hours, please reach out to our support team.',
          },
          {
            question: 'How do I cancel my order?',
            answer:
              'If you would like to cancel your order, please email <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
          },
          {
            question: 'How do I request a refund?',
            answer:
              'If you would like to request a refund, please email <a href="mailto:{{supportEmail}}">{{supportEmail}}</a>.',
          },
          {
            question: 'Where can I find my order email address?',
            answer:
              'You can communicate with your expert through your messages; we recommend this as it is the most secure and quickest method. But if you would like to use your email to communicate.',
          },
          {
            question: 'How will my writer contact me?',
            answer:
              'You will communicate with your writer by using your {{brand}} account and through the built-in secure messaging feature.',
          },
        ],
        troubleshootingTitle: 'Troubleshooting',
        workingWithWriter: [
          {
            question: "What are my writer's credentials?",
            answer:
              'We have a network of over 1,200 writers, of certified career coaches, former and current hiring managers, HR professionals, recruiters, and individuals with specialized experience in over 65 industries. This ensures we can match you with an expert who is a strong match for your needs and knows the ins and outs of your industry.',
          },
          {
            question: 'Can I talk to my writer on the phone?',
            answer:
              'Talking to your writer on the phone is available as an additional service. If you would like to speak to your writer on the phone, you can add this service to your order on the <a href="/upgrade">Upgrade</a> page',
          },
          {
            question: 'How many revisions can I make?',
            answer:
              'After you receive your first draft, you have seven days for revisions. Working with your writer is a collaborative effort, and we encourage you to deliver feedback and suggestions to your writer as soon as you can. We recommend consolidating your feedback and sending it to your writer all in one message.<br>We recommend providing your revisions in as few rounds as possible to ensure that your writer is able to consider the impact of your requests on the strategy as a whole. With this approach, we find that few clients need more than 2 rounds of revision.',
          },
          {
            question: 'I am not happy with my first draft. What should I do?',
            answer:
              'While our writers aim to get you a first draft that you can use immediately, they don’t always hit a bulls-eye on the first shot. That is why you have a seven-day revision window to collaborate with your writer to get the best document possible.',
          },
        ],
        workingWithWriterTitle: 'Working With Your Writer',
      },
      Messaging: {
        emptyMessageAlt: 'Empty message icon',
        howTo: {
          header: 'How to work with your {{expert}}',
          steps: {
            default: {
              howToText1:
                'Your writer will use your questionnaire and old documents to create your documents, then its time to collaborate to iron out the details.',
              howToText2:
                '1. Try to fit all your edits for this document into a single email.',
              howToText3:
                '2. The quicker you send edits, the sooner you’ll see changes.',
              howToText4:
                '3. Remember the document’s content is what lands the job! Focus on that in your edits.',
            },
            interviewPrep: {
              howToText1:
                'Your writer will use your questionnaire and old documents to create your documents, then its time to collaborate to iron out the details.',
              howToText2:
                '1. The first step is to share some background information about you including your resume and a job posting you are interested in pursuing. You can message this to your Expert.',
              howToText3:
                '2. Schedule a time to talk with your Expert and be sure to mark this in your calendar. Set yourself up in a quiet space free from distractions with a good internet connection.',
              howToText4:
                '3. You will run through a mock interview with your coach and then get tailored feedback on how to refine your responses - this is the ideal time to work with your Expert and ask for specific feedback.',
            },
          },
        },
        items: 'Items',
        messagePlaceholder: 'Type a message',
        noMessages: 'You don’t have any messages yet',
        orderDetails: 'Order Details',
        completedOrders: 'Completed Orders',
        pagination: '{{number}} of {{total}}',
        revision: 'Revision period ends: {{date}}',
        send: 'Send',
        sending: 'Sending...',
        sendFailure: 'Something went wrong, Please try again.',
        seeLess: 'See Less',
        support: 'Support',
        supportText:
          'If you have any questions you can contact our support team via email at <a href="mailto: {{email}}">{{email}}</a> or phone at <a href="tel:+{{phone}}">{{phone}}</a>. Our team is standing by, every weekday from <strong>9am to 7pm ET.</strong>',
        writer: 'Writer',
        expert: 'Expert',
        messageDateFormat: "L/d/y '@' h:mm a",
        Attachments: {
          errors: {
            size: 'File too large: Please try again with a smaller file',
            virus: 'Virus detected: Please try again with a different file',
            generic:
              'File error: Please try again with a Word doc, PDF or .txt file',
          },
        },
        defaultSubject: 'New message from {{name}}',
        defaultSubjectNoName: 'New message',
        viewPastDocuments:
          'Your past documents are available in your portfolio',
        viewPortfolio: 'View Portfolio',
        completedDocument: {
          title: 'We’ve gifted you premium access to',
          copy: 'The final documents are being stored in Career.io along with a free trial of premium access! Take the opportunity to utilize their full suite of career tools.',
          stepsTitle: 'take the next steps in your job search:',
          steps: [
            'Find jobs with the search engine and tracker',
            'Improve with the AI-powered interview tool',
            'Evaluate job offers with the salary analyzer',
          ],
          viewDocuments: 'View Documents',
          learnMore: 'Learn More',
          poweredBy: 'Powered by',
        },
      },
      Notifications: {
        [NotificationTypes.Welcome]: {
          title: 'Welcome to {{brand}}!',
          body: 'View a tour of how this portal helps you to get the most out of our services.',
        },
        [NotificationTypes.ExpertAssigned]: {
          title: "You've been matched!",
          body: 'We’ve paired you with {{expertName}}',
        },
        [NotificationTypes.OrderReadyEarly]: {
          title: 'Your order arrived early!',
          body: 'Great news your expert delivered your first draft earlier than expected!',
        },
        [NotificationTypes.OrderReady]: {
          title: 'Hurray!  Your order is ready!',
          body: 'Hooray!  Your expert has delivered your document.',
        },
        [NotificationTypes.OrderLate]: {
          title: 'Uh-oh, slight delay ahead!',
          body: "We are experiencing some delays.  Don't worry, your order will be back on track soon.",
        },
        [NotificationTypes.OrderReassigned]: {
          title: 'We found you a new expert!',
          body: "{{expertName}} can't wait to work with you!  Head over to messages and say hello.",
        },
        [NotificationTypes.OrderItemAdjusted]: {
          title: 'Your order has been updated!',
          body: 'Your original {{originalProduct}} order has been updated to a {{upgradedProduct}}!',
        },
        [NotificationTypes.OrderItemComped]: {
          title: 'A gift from us to you!',
          body: 'Thank you for being so understanding.  We’ve added a gift to your order, and apologize for any inconvenience.',
        },
        [NotificationTypes.OrderUnassigned]: {
          title: 'We unassigned your expert',
          body: 'Your expert has been removed from your order, and we are working on finding the best replacement!',
        },
        [NotificationTypes.OrderCloned]: {
          title: 'Order marked for reassignment!',
          body: "Sometimes things don't go exactly to plan.  But not to worry, we are now searching for a new expert to help take you to the finish line.",
        },
        [NotificationTypes.OrderReceived]: {
          title: 'Your order has been received!',
          body: 'A {{items}} has been added to your portfolio.',
        },
        no_notifications: 'No notifications',
        menu_title: 'Notifications',
        date_ago: '{{date}} ago',
      },
      Navigation: {
        back: 'Back',
        cart: 'Cart',
        updates: 'Updates',
      },
      NavMenu: {
        account: 'Account',
        greeting: 'Hi, {{name}}',
        home: 'Home',
        logOut: 'Log out',
        messages: 'Messages',
        portfolio: 'Portfolio',
        upgrade: 'Upgrade',
      },
      Modals: {
        Wrapper: {
          back: 'Back',
        },
        variants: {
          MatchedWithWriter: {
            cta: 'Got it',
            title: 'You’ve been matched!',
            copy: 'We have the perfect expert for your project. They’re getting started on your documents and will message you if any questions come up.',
          },
          MatchedWithNewWriter: {
            cta: 'Got it',
            title: 'We’ve matched you with a new expert!',
            copy: 'Thanks for your patience. We feel confident that your new expert will be a much better match for your goals and industry.',
          },
          GettingStarted: {
            welcomeTitle: 'Welcome, {{user.first_name}}!',
            welcomeTitleNoName: 'Welcome!',
            welcomeCopy:
              'We couldn’t be happier that you have chosen us to help you on your career journey.',
            trackTitle: 'Track your order!',
            trackCopy:
              'You can use your {{brandName}} account to see the progress of your order.  Once you receive a first draft you have a 7 day revision period to fine-tune your document into a final draft.',
            trackCopyTopInterview:
              'You can use your {{brandName}} account to see the progress of your order.',
            workTitle: 'Work with your expert!',
            workCopy:
              'Working with your expert is a collaborative experience.  You provide information about your work history and goals, and your expert will translate this into an amazing document to elevate your career.',
            workCopyTopInterview:
              'Working with your coach is a collaborative experience.  You can use your {{brandName}} account to schedule and communicate with your expert.',
            tipsTitle: 'Finding key career tips and information!',
            tipsCopy:
              'While you wait for your expert to deliver first drafts, we encourage you to explore our career tips and advice blog.  Learn the best interview skills, networking techniques, and job searching tips!',
          },
          EarlyOrder: {
            cta: 'Back to home',
            title: 'Your order arrived early!',
            copy: 'Your expert has been working hard at crafting you the perfect document and was able to get it to you earlier than expected, remember this is the first draft and you have 7 days from today to request edits and polish this document!',
          },
          LateOrder: {
            cta: 'Back to home',
            title: 'Uh-oh, we’ve hit capacity!',
            copy: 'Unfortunately this can occasionally happen.  Your expert has been flooded with orders, and will have your draft ready a couple days later than expected. Thank you for your patience.',
          },
          OrderReceived: {
            cta: 'Back to home',
            title: 'Your order has been received!',
            copy: 'Great choice {{name}}, a {{productName}} has been added to your portfolio.',
            copyNoName:
              'Great choice! A {{productName}} has been added to your portfolio.',
            copyNoItems:
              'Great choice! Your purchase has been added to your portfolio.',
          },
          OrderUpgraded: {
            cta: 'Back to home',
            title: 'Your order has been upgraded!',
            copy: 'Great choice {{name}}, your original {{originalProduct}} order has been upgraded to a {{upgradedProduct}}.',
            copyNoName:
              'Great choice! Your original {{originalProduct}} order has been upgraded to a {{upgradedProduct}}.',
          },
          FreeService: {
            cta: 'Back to home',
            title: 'A gift from us to you!',
            copy: 'Thank you for being so understanding, we’ve added a {{productName}} to your order to apologize for the previous inconvenience.',
          },
          Feedback: {
            nps: {
              title:
                'How likely are you to recommend {{brandName}} to a friend?',
              cta: 'Confirm',
              notLikely: 'Not at all likely',
              veryLikely: 'Very likely',
              optOut: 'I don’t normally recommend resume services to friends',
            },
            thanks: {
              title: 'We appreciate your feeback',
              copy: 'This helps us to improve our product, if you have anything else to add, tell us more.',
              cta: 'Back to home',
            },
            freeform: {
              title: 'Send Feedback',
              label: 'Tell us what you love, or what we could be doing better',
              cta: 'Send',
            },
            sent: {
              title: 'Your feedback has been sent',
              copy: 'Thanks again for sharing your thoughts on {{brandName}}, our team will look over it shortly.',
              cta: 'Back to home',
            },
          },
          DocumentReady: {
            cta: 'View {{productName}}',
            cta_plural: 'View documents',
            title: 'Hurray! Your {{productName}} is ready',
            title_plural: 'Hurray! Your documents are ready',
            copy: 'Your expert has been working hard at crafting you the perfect document, remember this is the first draft and you have 7 days from today to request edits and polish this document!',
          },
          Download: {
            cta: 'Download',
            title: 'Select a file type',
            copy: 'Choose one or multiple types to download',
            pdf: 'PDF',
            word: 'Microsoft Word',
          },
          ApproveDocument: {
            approve: {
              cta: 'Confirm',
              title: 'Are you sure you want to approve?',
              copy: 'This will close your {{productName}} with {{expertName}} so she knows its complete.',
            },
            approved: {
              cta: 'Continue',
              title: 'Your {{productName}} has been approved!',
              copy: 'Thank you for approving your order, we will let your expert know.',
            },
          },
          ApproveOrder: {
            cta: 'Confirm',
            title: 'Are you sure you want to approve this order?',
            copy: "This will close your order with {{expertName}} so they know it's complete. The items in this order are listed below.",
            draftFormat: "'Draft {{revision}}': MMMM d 'at' p",
          },
          OrderUnassigned: {
            cta: 'Okay',
            title: 'Order has been unassigned',
            copy: 'Your expert was removed from your order. Not to worry we are already working hard on finding a replacement expert!',
          },
          OrderCloned: {
            cta: 'Okay',
            title: 'We marked your order for reassignment',
            copy: 'Sometimes things dont go exactly to plan.  We are looking for a new expert to take your order to the finish line.',
          },
          FirstDraftLinkedIn: {
            cta: '',
            title: '{{expertName}} has delivered your LinkedIn profile',
            copy: {
              processDescription:
                'We care about keeping your information safe - this is why we provide a secure document that walks you through how to transfer your new profile to the LinkedIn platform.',
              deliveredOn: 'Delivered on',
              documentType: 'Document Type',
              documentType_plural: 'Document Types',
              reminderHeader: 'A few things to keep in mind:',
              reminders: {
                preview:
                  'Toggle between your LinkedIn Preview and LinkedIn Worksheet to see how your profile should look on the platform.',
                login:
                  'This process is secure and ensures that we never have to ask for your login details.',
              },
              support:
                'If you have any questions about how to use the LinkedIn worksheet please do not hesitate to contact {{expertName}}! Your success is our success.',
              yourWriter: 'Your writer',
            },
            dateFormat: "EEEE, MMMM d 'at' h:mm a",
            itemName: 'LinkedIn',
          },
          FirstDraft: {
            cta: '',
            title: '{{expertName}} has delivered your first draft!',
            copy: {
              processDescription:
                'Your writer has used your questionnaire and old documents to create this document. Now its time to collaborate to iron out the details',
              deliveredOn: 'Delivered on',
              documentType: 'Document Type',
              documentType_plural: 'Document Types',
              reminderHeader: 'A few things to keep in mind:',
              reminders: {
                email:
                  'Try to fit all your edits for this document into a single email.',
                speed:
                  'The quicker you send edits, the sooner you’ll see changes. ',
                contents:
                  'Remember the document’s content is what lands the job! Focus on that in your edits.',
              },
              support:
                'If you experience issues, please contact our support team.',
              yourWriter: 'Your writer',
            },
            dateFormat: "EEEE, MMMM d 'at' h:mm a",
          },
          Reschedule: {
            cta: 'Continue to reschedule',
            title: 'Are you sure you want to reschedule?',
            copy: 'We understand that plans change, and allow up to two schedule changes/cancellations with your expert..',
          },
          AppointmentReminder: {
            title: 'Your appointment is in {{timePeriod}}!',
            titleNow: 'Your appointment is happening now!',
            copy: '{{expertName}} is looking forward to speaking with you about your professional goals!',
            cta: {
              dismiss: 'Back to home',
              join: 'Go to session',
            },
            timeFormat: 'h:mm a',
            dateFormat: 'cccc, LLLL d, y',
            sessionTime: '{{startTime}} - {{endTime}}, {{date}}',
          },
          CIOInfoModal: {
            title: 'Career.io is the first complete career platform!',
            copy: 'There is everything you need to manage each step in your career all in one place. We’ll be gifting you free access once your final {{brand}} documents are delivered.',
            featuresTitle: 'whatever you need:',
            features: [
              'Resume Builder',
              'Salary Analyzer',
              'Resume Optimizer',
              'Interview Prep',
              'Job Search',
              'Career Plans',
              'Job Tracker',
              'Career Pathways',
            ],
          },
        },
      },
      Portfolio: {
        NoPackage: {
          noDocuments: "You don't have any documents in the works",
          careerJourney:
            "When you're ready to continue your career journey, check out our other services.",
          viewServices: 'View Services',
        },
        PastItems: {
          title: 'Past Items:',
          loading: 'We’re confirming your order details, hang tight...',
        },
        ItemCard: {
          dateTextFormat: 'M/d/yy',
          dateText: {
            [ItemStatusTypes.FirstDraft]: 'Draft expected: {{date}}',
            [ItemStatusTypes.AwaitingWriterMatch]: 'Draft expected: {{date}}',
            [ItemStatusTypes.RevisionMode]: 'Revision period: {{date}}',
            [ItemStatusTypes.AwaitingReply]: 'Revision period: {{date}}',
            [ItemStatusTypes.Complete]: 'Completed on: {{date}}',
          },
        },
        CIOBanner: {
          title: 'We’ve gifted you premium access to',
          copy: 'Your final documents are accessible through Career.io, make sure to utilize their comprehensive selection of career tools.',
        },
      },
      TimelineWidget: {
        DeliveryPopper: {
          gotIt: 'Got it',
          expectedDeliveryDateFormat: 'MMM d, yyyy',
        },
        CTA: {
          [OrderStatusTypes.FirstDraft]: {
            reminder:
              "Remember to send edits by replying to your expert's last message",
            button: 'View documents',
          },
          OrderApproval: {
            reminder:
              'Let your expert know that you are happy with your documents.',
            button: 'Approve Order',
            buttonDocuments: 'Review Documents',
          },
        },
      },
      Rating: {
        HomeWidget: {
          title: 'Your past order with {{expertName}} on {{date}}',
          titleNoName: 'Your past order on {{date}}',
          titleDateFormat: 'MMM d, yyyy',
          services: 'Services: {{services}}',
          leaveReview: 'Leave a review:',
          recommendQuestion: 'Would you recommend the expert you worked with?',
          viewOrderInPortfolio: 'View Order',
        },
        ApprovalOrderModalContainer: {
          text: 'Once you are happy with your documents you can Approve, this will let your writer know you have closed the order.',
        },
      },
      Errors: {
        FlashMessage: {
          errorOccured: 'An error has occured.',
          showReport: 'See detailed report.',
          hideReport: 'Hide detailed report.',
        },
      },
      AppUpgrade: {
        title: 'The system was recently updated.',
        button: 'Refresh the page to see updates.',
      },
      LiteFooter: {
        header: 'Get Started',
        cta: 'View Packages',
        copy: `Our professional resume-writing service has helped over 1 million professionals.<br />
          View our packages and make a purchase to create an account.`,
        critique:
          'Looking for your resume critique? Find it through the original link in your email.',
      },
      OrderWidget: {
        headline: {
          default: 'Your documents are arriving soon',
          emailOnly: 'This service is fulfilled through email',
          closed: 'Thanks for working with us!',
          viewDocsInCIO: 'View your final documents in Career.io',
          [OrderTypes.Standard]: {
            [OrderStatusTypes.Open]:
              'Your questionnaire has not been submitted',
            [OrderStatusTypes.ExpertAssignment]:
              'Your {{itemName}} is arriving on {{firstDraftDate}}',
            [`${OrderStatusTypes.ExpertAssignment}_plural`]:
              'Your documents are arriving on {{firstDraftDate}}',
            [OrderStatusTypes.FirstDraft]:
              'Your {{itemName}} is arriving on {{firstDraftDate}}',
            [`${OrderStatusTypes.FirstDraft}_plural`]:
              'Your documents are arriving on {{firstDraftDate}}',
            [OrderStatusTypes.LinkedInDue]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.SchedulePhone]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.Phone]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.ScheduleInterview]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.Interview]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.RevisionPeriod]:
              'Your revision period ends {{revisionPeriodDate}}',
            [OrderStatusTypes.Completed]: 'Rate Your Order Today!',
            [OrderStatusTypes.PartialRefund]:
              'A refund has been processed for this order',
            [OrderStatusTypes.FullRefund]:
              'A refund has been processed for this order',
            [OrderStatusTypes.Reassigned]:
              'We are on the search for a new writer for this order',
          },
          [OrderTypes.VoiceOnly]: {
            [OrderStatusTypes.ExpertAssignment]:
              'We will connect with you before {{orderDueDate}}',
            [OrderStatusTypes.ScheduleInterview]:
              '{{expertName}} would love to connect with you before {{orderDueDate}} ',
            [OrderStatusTypes.SchedulePhone]:
              '{{expertName}} would love to connect with you before {{orderDueDate}} ',
            [OrderStatusTypes.Interview]:
              'Your call with {{expertName}} is coming up',
            [OrderStatusTypes.Phone]:
              'Your call with {{expertName}} is coming up',
            [OrderStatusTypes.Completed]: 'Rate Your {{itemName}} Today!',
            [OrderStatusTypes.PartialRefund]:
              'A refund has been processed for this order',
            [OrderStatusTypes.FullRefund]:
              'A refund has been processed for this order',
            [OrderStatusTypes.Reassigned]:
              'We are on the search for a new writer for this order',
            summary:
              '{{expertName}} is working on the summary for your {{itemName}}',
          },
        },
        helperCopy: {
          cioTrial: 'You’ll also receive premium access to Career.io Premium',
          emailOnly:
            'Check for an email from {{brand}} to sign up for your service.',
          emailOnlyDefault: 'Check for an email to sign up for your service.',
          [OrderTypes.AddOnOnly]: {
            [ItemTypes.RushOrder]:
              'Your {{itemName}} has successfully been applied to your order. The updated due date is reflected on your original order',
            [ItemTypes.Guarantee]:
              'Your {{itemName}} has successfully been applied to your {{brandProduct}} order',
          },
          [OrderTypes.Standard]: {
            [OrderStatusTypes.ExpertAssignment]:
              'We will match you with the perfect expert within 3-4 days',
            [OrderStatusTypes.LinkedInDue]:
              '{{expertName}} will send you your Linkedin profile by the end of the revision period',
            [OrderStatusTypes.SchedulePhone]:
              'The first draft is a conversation starter, let us know what you think!',
            [OrderStatusTypes.Phone]:
              'The first draft is a conversation starter, let us know what you think!',
            [OrderStatusTypes.ScheduleInterview]:
              'The first draft is a conversation starter, let us know what you think!',
            [OrderStatusTypes.Interview]:
              'The first draft is a conversation starter, let us know what you think!',
            [OrderStatusTypes.FirstDraft]:
              '{{expertName}} is working on your {{itemName}} as we speak ',
            [`${OrderStatusTypes.FirstDraft}_plural`]:
              '{{expertName}} is working on your documents as we speak ',
            [OrderStatusTypes.RevisionPeriod]:
              'The first draft is a conversation starter, let us know what you think!',
            [OrderStatusTypes.Completed]:
              'This order closed on {{statusDate}}. It was a pleasure working with you!',
            [OrderStatusTypes.Reassigned]:
              'We will match you with the perfect expert within 3-4 days',
            [OrderStatusTypes.Open]:
              'Please complete and submit your questionnaire so we can match you with an expert',
          },
          [OrderTypes.VoiceOnly]: {
            [OrderStatusTypes.ExpertAssignment]:
              'We are the hunt for the perfect expert for you to work with.',
            [OrderStatusTypes.ScheduleInterview]:
              'Its time to schedule your interview session with {{expertName}}',
            [OrderStatusTypes.SchedulePhone]:
              'Its time to schedule your {{itemName}} with {{expertName}}',
            [OrderStatusTypes.Interview]:
              '{{expertName}} is excited to connect with you on {{expectedDate}}',
            [OrderStatusTypes.Phone]:
              '{{expertName}} is excited to connect with you on {{expectedDate}}',
            [OrderStatusTypes.Completed]:
              'This order closed on {{statusDate}}. It was a pleasure working with you!',
            [OrderStatusTypes.Reassigned]:
              'We will match you with the perfect expert within 3-4 days',
            summary:
              '{{expertName}} is working on the summary for your {{itemName}}',
          },
        },
        cta: {
          [OrderTypes.Standard]: {
            [OrderStatusTypes.ExpertAssignment]: 'View Documents',
            [OrderStatusTypes.LinkedInDue]: 'View Documents',
            [OrderStatusTypes.SchedulePhone]: 'View Documents',
            [OrderStatusTypes.Phone]: 'View Documents',
            [OrderStatusTypes.ScheduleInterview]: 'View Documents',
            [OrderStatusTypes.Interview]: 'View Documents',
            [OrderStatusTypes.FirstDraft]: 'Message Your Writer',
            [OrderStatusTypes.RevisionPeriod]: 'View Documents',
            [OrderStatusTypes.Completed]: 'Rate My Order',
            [OrderStatusTypes.Reassigned]: 'View Documents',
            [OrderStatusTypes.FullRefund]: 'View Documents',
            [OrderStatusTypes.PartialRefund]: 'View Documents',
            [OrderStatusTypes.Open]: 'Complete Questionnaire',
          },
          [OrderTypes.VoiceOnly]: {
            [OrderStatusTypes.ExpertAssignment]: 'Prepare for {{itemName}}',
            [OrderStatusTypes.SchedulePhone]: 'Schedule Your Call',
            [OrderStatusTypes.Phone]: 'Prepare for Phone Consultation',
            [OrderStatusTypes.ScheduleInterview]: 'Schedule Your Session',
            [OrderStatusTypes.Interview]: 'Prepare for Interview Session',
            [OrderStatusTypes.Completed]: 'Rate My {{itemName}}',
          },
          default: 'View Documents',
          submittingRating: 'Submitting Rating ...',
        },
        orderNumber: 'ORDER # ',
        dueDateFormat: 'MMM d',
        closedDateFormat: 'MMM d, yyy',
        yourExpert: 'Your expert',
        emailFulfilled: 'Fulfilled through email',
        dueForUpdate: 'Due for update',
        dueForUpdateToolTip:
          'Career experts recommend updating your career documents every 6 months to maximize opportunities.',
      },
      OrderProgress: {
        checkAlt: 'Checkmark icon',
        title: 'Order Progress',
        statuses: {
          [OrderStatusTypes.Open]: {
            futureTitle: 'Questionnaire Submitted',
            completedTitle: 'Questionnaire Submitted',
          },
          [OrderStatusTypes.CompleteQuestionnaire]: {
            futureTitle: 'Questionnaire Submitted',
            completedTitle: 'Questionnaire Submitted',
          },
          [OrderStatusTypes.ExpertAssignment]: {
            futureTitle: 'Match with a writer',
            completedTitle: 'Matched with {{expertName}}',
            activeCopy: '{{expertName}} is now working on your documents!',
            activeCTA:
              '<messageLink>Send {{expertName}} more information about your career goals</messageLink>',
          },
          [OrderStatusTypes.LinkedInDue]: {
            futureTitle: 'Review LinkedIn',
            completedTitle: 'LinkedIn Profile Delivered',
          },
          [OrderStatusTypes.SchedulePhone]: {
            futureTitle: 'Schedule Your Call',
            completedTitle: 'Call Scheduled',
          },
          [OrderStatusTypes.Phone]: {
            futureTitle: 'Speak with Your Writer',
            completedTitle: 'Call Complete',
          },
          [OrderStatusTypes.ScheduleInterview]: {
            futureTitle: 'Schedule Your Interview Session',
            completedTitle: 'Session Scheduled',
          },
          [OrderStatusTypes.Interview]: {
            futureTitle: 'Interview Session {{interviewSessionNumber}}',
            completedTitle: 'Session Complete',
          },
          [OrderStatusTypes.FirstDraft]: {
            futureTitle: 'Review First Draft',
            completedTitle: 'First Draft Delivered',
            activeCopy:
              'You have recieved your first draft. What do you think thus far?',
            activeCTA:
              '<messageLink>Send {{expertName}} feedback</messageLink>',
          },
          [OrderStatusTypes.RevisionPeriod]: {
            futureTitle: 'Request Edits on Your Documents',
            completedTitle: 'Request Edits on Your Documents',
            activeCopy:
              'You have recieved your first draft. What do you think thus far?',
            activeCTA:
              '<messageLink>Send {{expertName}} feedback</messageLink>',
          },
          [OrderStatusTypes.RevisionPeriodStart]: {
            futureTitle: 'Request Edits on Your Documents',
            completedTitle: 'Request Edits on Your Documents',
            activeCopy:
              'You have recieved your first draft. What do you think thus far?',
            activeCTA:
              '<messageLink>Send {{expertName}} feedback</messageLink>',
          },
          [OrderStatusTypes.Completed]: {
            futureTitle: 'Close and Rate Your Order',
            completedTitle: 'Order Closed! Thank You!',
            activeCopy: 'Did you enjoy working with us?',
            activeCTA: '<feedbackModal>Tell us what you think!</feedbackModal>',
          },
        },
      },
      OrderWidgetItemDetails: {
        title: 'Item Details',
        items: {
          // @TODO How do we detect federal resumes?
          // @TODO How do I detect resume refreshes?
          [ItemTypes.Resume]: {
            name: 'Resume',
            description:
              'An expertly written and keyword-optimized resume that sets you apart.',
          },
          [ItemTypes.CoverLetter]: {
            name: 'Cover Letter',
            description:
              'A highly focused cover letter tailored directly to your job search goals.',
          },
          [ItemTypes.ThankYouLetter]: {
            name: 'Thank You Note',
            description:
              'A professionally written an effective thank you note to follow-up with after your interview.',
          },
          [ItemTypes.CurriculumVitae]: {
            name: 'Academic CV',
            description:
              'An expertly written and keyword-optimized academic CV is the key to applying to educational jobs.',
          },
          [ItemTypes.Guarantee]: {
            name: '60 Day Rewrite',
            description:
              'If you are not receiving two times more job interviews within 60 days of receiving your resume, we will write it again.',
          },
          [ItemTypes.PhoneCall]: {
            name: 'Phone Consultation',
            description:
              'Speak with your professional writer on the phone and discuss your document.',
          },
          [ItemTypes.InterviewPrep]: {
            name: 'TopInterview Session',
            description:
              'Refine and polish your interview skills through TopInterview Expert Coaching.',
          },
          [ItemTypes.PersonalConsultationInterviewPrep]: {
            name: 'TopInterview Session',
            description:
              'Refine and polish your interview skills through TopInterview Expert Coaching.',
          },
          [ItemTypes.PostingService]: {
            name: 'ResumeRabbit',
            description:
              'Express resume-posting features allow you to post to 50 job sites at once!',
          },
          [ItemTypes.RecruiterService]: {
            name: 'Recruiter.com',
            description:
              'Post your resume to our top recruiters. They keep your resume on file and follow up whenever they have an opening that’s a good fit for you.',
          },
          [ItemTypes.LinkedInDocument]: {
            name: 'LinkedIn',
            description:
              'A professional written LinkedIn profile to attract and impress employers looking at your profile!',
          },
          [ItemTypes.RushOrder]: {
            name: 'Rush Order',
            description: 'Expedited Turnaround',
          },
          [ItemTypes.CareerIO]: {
            name: 'Career.io Subscription',
            description:
              'Free access to a one-stop-shop, comprehensive career management platform, providing cohesive tools and resources all in one place; no matter where you are in your career.',
          },
          [`${ItemTypes.RushOrder24Hours}`]: {
            name: 'Rush Order',
            description: 'Expedited 24-Hour Turnaround',
          },
          [`${ItemTypes.RushOrder48Hours}`]: {
            name: 'Rush Order',
            description: 'Expedited 48-Hour Turnaround',
          },
          [`${ItemTypes.RushOrder72Hours}`]: {
            name: 'Rush Order',
            description: 'Expedited 72-Hour Turnaround',
          },
          [ItemTypes.ResumeEdit]: {
            name: 'Resume Review',
            description:
              'Get the first draft of your new resume delivered inside of <a href="https://career.io" rel="noopener noreferrer" target="_blank">Career.io’s</a> intuitive resume builder',
          },
          [ItemTypes.VideoCritique]: {
            name: 'Video Critique',
            description:
              'Get 10-minute video from our professional resume reviewers',
          },
          [ItemTypes.AutoApply]: {
            name: 'Auto Apply',
            description:
              'We’ll do all the job applications, you just have to turn up for the interviews',
          },
        },
      },
      VoiceDrivenItemDetails: {
        headline: {
          dateFormat: 'E, MMM d',
          timeFormat: 'h:mm bbbb',
          [ItemTypes.PhoneCall]: {
            [ItemStatusTypes.SchedulePhone]:
              'You will be able to schedule a time for your call once we find a great writer for your order!',
            [ItemStatusTypes.ConductCall]:
              'Your have a phone call scheduled for {{date}} at {{time}}',
          },
          [ItemTypes.InterviewPrep]: {
            [ItemStatusTypes.ScheduleInterview]:
              'You will be able to schedule a time for your interview once we find a great expert for your order!',
            [ItemStatusTypes.ConductInterview]:
              'Your have an interview scheduled for {{date}} at {{time}}',
          },
        },
        headlineHelper: {
          [ItemTypes.PhoneCall]: {
            [ItemStatusTypes.SchedulePhone]:
              'Check your calendar for a time that works well for you',
            [ItemStatusTypes.ConductCall]:
              'Be prepared to go over your documents and career goals',
          },
          [ItemTypes.InterviewPrep]: {
            [ItemStatusTypes.ScheduleInterview]:
              'Check your calendar for a time that works well for you',
            [ItemStatusTypes.ConductInterview]:
              'Be prepared to go over your documents and career goals',
          },
        },
      },
      Upsells: {
        addToOrder: 'Add to my order',
        recommendedBy: 'Recommended by {{brand}}',
        notTooLate: 'Not too late to add to your order',
        expirationDateFormat: 'MM/dd',
        shortPitchDateFormat: 'MMMM d',
        units: {
          day: 'DAY',
          hour: 'HR',
          minute: 'MIN',
          second: 'SEC',
        },
        actFast: 'Act fast! this offer ends ',
        shortPitch: {
          [ItemTypes.RushOrder]: 'Get your resume faster.',
          [ItemTypes.RushOrder24Hours]: 'Get your resume within 24 hours.',
          [ItemTypes.RushOrder48Hours]: 'Get your resume within 48 hours.',
          [ItemTypes.RushOrder72Hours]: 'Get your resume within 72 hours.',
          [ItemTypes.ThankYouLetter]:
            'The best way to make sure you get the offer is with an effective thank you note.',
          [ItemTypes.LinkedInDocument]:
            'With a professionally written profile, you can expect more interviews and job offers.',
          [ItemTypes.PhoneCall]:
            "Speak with your writer on the phone about things you'd like to see in your resume.",
          [ItemTypes.Guarantee]:
            'Get double the amount of interviews within 60 days!',
          [ItemTypes.InterviewPrep]:
            'Refine and polish your interview skills through TopInterview Expert Coaching.',
          [ItemTypes.PostingService]:
            'Express resume-posting features allow you to post to 50 job sites at once!',
          [ItemTypes.RecruiterService]:
            'Post your resume to our top recruiters!',
          [ItemTypes.CoverLetter]:
            'Get a highly focused cover letter tailored directly to your job search goals.',
          [ItemTypes.Resume]:
            'An expertly written and keyword-optimized resume refresh that sets you apart.',
        },
        upsellName: {
          [ItemTypes.RushOrder]: '48-Hour Rush Service',
          [ItemTypes.ThankYouLetter]: 'Thank You Letter',
          [ItemTypes.LinkedInDocument]: 'Linkedin Makeover',
          [ItemTypes.PhoneCall]: 'Phone Consultation',
          [ItemTypes.Guarantee]: '60 Day Guarantee',
          [ItemTypes.InterviewPrep]: 'TopInterview Coaching',
          [ItemTypes.PostingService]: 'Resume Distribution',
          [ItemTypes.RecruiterService]: 'Recruiter Outreach',
          [ItemTypes.CoverLetter]: 'Cover Letter',
          [ItemTypes.Resume]: 'Resume Refresh',
          [ItemTypes.AutoApply]:
            'We Apply For you: {{ quantity }} AutoApply Applications',
        },
        billboard: {
          headline: {
            [ItemTypes.RushOrder]: 'Get your resume sooner!',
            [ItemTypes.ThankYouLetter]:
              'Complete your career package with a Thank You Letter',
            [ItemTypes.LinkedInDocument]:
              "Do you have a profile on today's top recruiting platform?",
            [ItemTypes.PhoneCall]: 'Talk with your expert on the phone',
            [ItemTypes.Guarantee]: ' Day Guarantee',
            [ItemTypes.InterviewPrep]:
              'TopInterview is the #1 rated interview coaching service',
            [ItemTypes.PostingService]:
              'Post your Resume to 50+ job sites with a single click',
            [ItemTypes.RecruiterService]:
              "We'll send your Resume to today's leading industry recruiters",
            [ItemTypes.CoverLetter]:
              'Complete your career package with a Cover Letter',
          },
          shortPitch: {
            [ItemTypes.RushOrder]:
              'Add our {{itemName}} to get it by {{rushDate}} for just {{price}}.',
            [ItemTypes.ThankYouLetter]:
              'In a recent survey 68% of hiring managers and recruiters said a {{itemName}} impacted their decision on a candidate',
            [ItemTypes.LinkedInDocument]:
              '97% of recruiters use LinkedIn to evaluate and screen candidates.',
            [ItemTypes.PhoneCall]:
              'For a limited time, discuss career goals and edits with your writer on the phone for just {{price}}.',
            [ItemTypes.Guarantee]:
              'If you are not receiving 2x the job interviews within 60 days of getting your resume, we will write it again free-of-charge, no questions asked.',
            [ItemTypes.InterviewPrep]:
              'Our interview coaching service has prepared clients to successfully interview with some of the top companies in the world.',
            [ItemTypes.PostingService]:
              'We’ve partnered with ResumeRabbit to provide a service that posts your finished resume to today’s top job boards, all for the low price of {{price}}.',
            [ItemTypes.RecruiterService]:
              'We’ve partnered with Recruiter.com to get your resume in front of recruiters from leading companies, all in a single click.',
            [ItemTypes.CoverLetter]:
              'Get a highly focused {{itemName}} tailored directly to your job search goals.',
          },
          cta: {
            [ItemTypes.RushOrder]: 'Speed up your order',
            [ItemTypes.ThankYouLetter]: 'Add to your order',
            [ItemTypes.LinkedInDocument]:
              'Add a LinkedIn Profile to your order',
            [ItemTypes.PhoneCall]: 'Add a phone call to your order',
            [ItemTypes.Guarantee]: 'Add 60 Day Guarantee to your order',
            [ItemTypes.InterviewPrep]: 'Add to your order',
            [ItemTypes.PostingService]: 'Add to your order',
            [ItemTypes.RecruiterService]: 'Add to your order',
            [ItemTypes.CoverLetter]: 'Add to your order',
          },
        },
        shoppingCart: {
          shortPitch: {
            [ItemTypes.RushOrder]:
              '{{expertName}} will rush to write your documents within 24 hours! Get your first draft as soon as {{date}}.',
            [ItemTypes.ThankYouLetter]:
              'The best way to make sure you get the offer is with an effective thank you note.',
            [ItemTypes.LinkedInDocument]:
              'With a professionally written profile, you can expect more interviews and job offers.',
            [ItemTypes.PhoneCall]:
              "Speak with your writer on the phone about things you'd like to see in your resume.",
            [ItemTypes.Guarantee]:
              'Get double the amount of interviews within 60 days!',
            [ItemTypes.InterviewPrep]:
              'Refine and polish your interview skills through TopInterview Expert Coaching.',
            [ItemTypes.PostingService]:
              'Express resume-posting features allow you to post to 50 job sites at once!',
            [ItemTypes.RecruiterService]:
              'Post your resume to our top recruiters!',
            [ItemTypes.CoverLetter]:
              'Get a highly focused cover letter tailored directly to your job search goals.',
            [ItemTypes.Resume]:
              'An expertly written and keyword-optimized resume refresh that sets you apart.',
            [ItemTypes.AutoApply]:
              'Our AutoApply specialists apply for you. This saves you time and avoids mistakes that can cost you the interview. AutoApply will complete up to {{ quantity }} applications within the first 30 days of downloading your resume!',
          },
          bundleShortPitch: {
            [Bundles.NextStep]:
              'You’ll need the perfect pitch to ensure you’re confidently prepared for every interview.',
            [Bundles.AccelerateSearch]:
              'Need to expedite your job search? We have the tools to help you work smarter, not harder and find that new job ASAP.',
            [Bundles.DigitallySavvy]:
              'Today’s job search is digital. Eye-catching LinkedIn profiles and Zoom interviews, are you prepared?',
            [Bundles.WinInterview]:
              'Curveball questions, tough interviewers, you have to confidently be prepared for anything, including the follow-up. We can help.',
            [Bundles.WriterExpertise]:
              'Your new, eye-catching resume won’t be as effective if you don’t have an optimized, supporting LinkedIn profile and cover letter.',
            [Bundles.GetWordOut]:
              'These two express posting services will help you get your resume out to top job sites and off to recruiters to get the ball rolling.',
            [Bundles.Linkedin]:
              'A professional LinkedIn makeover ensures you’re presenting an eye-catching and cohesive professional presence to hiring managers resulting in increased views and offers.',
            [Bundles.ExpertConnect]:
              'Gain 1:1 access to your resume writer and interview coach to ensure your goals and pitch are perfectly presented to every hiring manager.',
          },
          orderEnhancement: {
            packageDescription:
              "{{userName}}, let's upgrade your {{packageName}}.",
            packageDescriptionNoName: "Let's upgrade your {{packageName}}.",
            addItems: 'Add these items to your order for a limited time:',
          },
          checkoutModal: {
            checkout: 'Checkout',
            itemsSelected: '{{itemCount}} ITEM SELECTED',
            itemsSelected_plural: '{{itemCount}} ITEMS SELECTED',
          },
          addToCart: 'Add to cart',
          add: 'Add',
          remove: 'Remove',
          expiresIn: 'Expires in ',
          saveBundles: 'Save with our bundled services',
        },
        expandableBillboard: {
          title: {
            [ItemTypes.Resume]: 'Resume Refresh Service',
          },
          shortPitch: {
            [ItemTypes.Resume]:
              'Work with our professional resume writers to refresh/update your resume with your new skills and experience.',
          },
          cta: {
            [ItemTypes.Resume]: 'Get Started',
          },
          toggle: {
            [ItemTypes.Resume]: 'Learn More',
          },
          expanded: {
            title: {
              [ItemTypes.Resume]: 'The importance of updating your resume',
            },
            shortPitch: {
              [ItemTypes.Resume]:
                'Studies show that 60% of professionals update their resume regularly to stay competitive in the job market.',
            },
            percentage: {
              [ItemTypes.Resume]: '60%',
            },
            cta: {
              [ItemTypes.Resume]: 'Add to order',
            },
            bullets: {
              [ItemTypes.Resume]: {
                one: {
                  bold: 'Keyword optimized ',
                  normal:
                    '- Your resume will be optimized to pass through Applicant Tracking Systems.',
                },
                two: {
                  bold: 'Formatted for success ',
                  normal: "- Formatting that will get an employer's attention.",
                },
                three: {
                  bold: 'Professionally written ',
                  normal: '- By experts that know your industry.',
                },
              },
            },
            expert: {
              name: {
                [ItemTypes.Resume]:
                  '- Amanda Augustine, TopResume Career Expert',
              },
              quote: {
                [ItemTypes.Resume]:
                  '"The best time to update your resume is when you don’t need to. Don’t wait until you’re desperate to leave your job or suddenly find yourself on the wrong end of a pink slip"',
              },
            },
          },
        },
      },
      Documents: {
        userDefaults: {
          name: 'Your Name',
          phone: '(333) 333-3333',
          profession: 'Your Profession',
          linkedin: 'www.linkedin.com/in/you',
          area: 'XX, Your State',
          cityOrState: 'Your City or State',
        },
        interviewPrep: {
          dateAndTime: 'Date and time',
          sessionType: 'Session type',
          dateFormat: "EEE, MMMM d 'at' h:mm a",
          preAppointment: {
            heading: {
              preMatch: 'Sit tight while we find you the perfect expert!',
              postMatch: 'Your upcoming coaching session with {{expertName}}.',
            },
            body: {
              processDescription:
                'You have made the incredible step and started your interview coaching, here’s how to get the most out of your session. ',
              reminderHeader: 'Your guide to a great session',
              reminders: {
                email:
                  'Choose a well-lit space with a steady internet connection and minimal background noise.',
                speed:
                  'Prepare for your mock interview like you would for a real one and get ready to learn!',
                contents:
                  'Don’t worry about getting everything down, your coach will send through a feedback document after the session.',
              },
              support:
                'Your coach will email you with instructions on how to join your video session. If you experience issues, email your coach.',
            },
            cta: {
              status: {
                pastDocs:
                  'Once all your sessions are complete you can view and download your feedback documents from your portfolio page',
              },
            },
            scheduling: {
              cantMakeAppointment: "Can't make your appointment?",
              reSchedule: 'Reschedule',
            },
          },
          postAppointment: {
            heading: 'Your past coaching session with {{expertName}}.',
            cta: {
              status: {
                doc: 'Review your feedback document',
                noDoc: '{{expertName}} is working on your feedback document',
                pastDocs:
                  'Documents from you previous sessions are available in your portfolio',
              },
              download: 'Download',
              view: 'View',
              purchase: 'Purchase a session',
            },
            body: {
              reminderHeader: 'There’s no stopping you now!',
              reminderDetails:
                'You have made the incredible step and started your interview coaching, and the great news is additional sessions only cost $99 per session! Be ready for anything with carefully developed curriculums covering a wide range of topics and interview styles.',
              takeaways: {
                header: {
                  elevator: 'Elevator Pitch',
                  behavior: 'Behavioral Interview',
                  unstructured: 'Unstructured Interview',
                  stress: 'Stress Interview',
                  salary: 'Salary Negotiation',
                },
                details: {
                  elevator:
                    'A 30-second, persuasive pitch to get people interested in your ideas or skill set. Perfect for networking events or chance encounters.',
                  behavior:
                    'Get help answering the ‘What Ifs’ and other scenario-based questions in a way that demonstrates your work style and ability to think on your toes. The behavior interview is the most common interview style you’ll encounter these days.',
                  unstructured:
                    'These free-flowing interviews are typically more casual in nature, but are a good way to flex your conversational muscles and give interviews a better sense of your personality and social skills.',
                  stress:
                    'From brain-teasers to rapid-fire questioning, this challenging interview style is designed to understand how you’ll perform under pressure and navigate difficult situations.',
                  salary:
                    'Discussing and negotiating salary is a skill that takes practice. Our coaches can help you tackle this tough topic, so you can get the paycheck you deserve.',
                },
              },
            },
          },
        },
        phoneCallPrep: {
          dateAndTime: 'Date and time',
          sessionType: 'Session type',
          dateFormat: "EEE, MMMM d 'at' h:mm a",
          heading: {
            preMatch: 'Sit tight while we find you the perfect expert!',
            postMatch: 'Your upcoming phone call with {{expertName}}',
          },
          body: {
            reminderHeader: 'Your guide to a great session',
            processDescription:
              'You have made the incredible step in choosing to work with {{brand}}, and to accelerate this journey speaking with one of our Experts. Here’s how to get the most out of your upcoming session.',
            reminders: [
              'Choose a well-lit space with a steady internet connection and minimal background noise.',
              'Prepare for your call by having your current resume ready and a list of specific questions/goals you would like to discuss with your Expert.',
              'Be on time for your call, the more time you have with your Expert the more ground you can cover.',
            ],
            support:
              'Your coach will email you with instructions on how to join your call session. If you experience issues, email your Expert they are here to help you!',
          },
          scheduling: {
            cantMakeAppointment: "Can't make your appointment?",
            reSchedule: 'Reschedule',
          },
          cta: {
            status: {
              pastDocs:
                'Once all your sessions are complete you can view and download your feedback documents from your portfolio page',
            },
          },
        },
      },
      LinkedInPreview: {
        tabs: {
          preview: 'LinkedIn Preview',
          howTo: 'How to implement',
        },
      },
      Reviews: {
        default: [
          {
            headline: 'Worth the investment',
            review:
              '“Definitely pleased with the final product! I now have a resume and cover letter that speaks well of my experiences and skills.”',
            rating: 5,
            user: 'Ron N.',
          },
          {
            headline: 'Amazing Product',
            review:
              '“My resume, cover letter, and LinkedIn profile needed a serious makeover. She transformed all of this into something amazing. I applied and after only 2 days, I got interviews. I would highly recommend TopResume services.”',
            rating: 5,
            user: 'Georgiana D.',
          },
          {
            headline: 'Pretty Good',
            review:
              '“This was an extremely easy process with outstanding results! I would recommend TopResume writers to all my friends.”',
            rating: 4,
            user: 'Carol Y.',
          },
          {
            headline: 'High Quality',
            review:
              '“My resume, cover letter and linkedin profile is artwork!”',
            rating: 5,
            user: 'Jun K.',
          },
          {
            headline: 'Very Effective',
            review:
              '“My views on LinkedIn have gone up by 19% in less than a week, amazing. Thank you!”',
            rating: 5,
            user: 'Leah W.',
          },
          {
            headline: 'Great Value',
            review:
              '“I signed up to ResumeRabbit on a Tuesday and by Thursday the job offers started pouring in. By Friday, I had a job set up!”',
            rating: 5,
            user: 'Julie M.',
          },
          {
            headline: 'Quick and Efficient',
            review:
              '“The VERY NEXT DAY, I received the contact from a ResumeRabbit posting, which led to the best job I could imagine”',
            rating: 4,
            user: 'Ken L.',
          },
        ],
        Zipjob: [
          {
            headline: 'I had a great experience with ZipJob',
            review:
              '“I had a great experience with ZipJob, specifically with Michael B. He completely rewrote my resume and did a fantastic job! I only requested a couple of minor changes to his first draft. The resume looked very professional and was written in a way that made me sound like a great candidate for a job. I sent it out and got an interview within days. I highly recommend ZipJob!”',
            rating: 5,
            user: 'Jennifer',
          },
          {
            headline: 'Money well spent',
            review:
              "“I saved so much time and aggravation by using ZipJob's services. The process was straightforward and their turnaround time was excellent. Jeremy was easy to work with and I was surprised that we never needed a phone call. Overall I'm thrilled with how my resume/cover letter turned out.”",
            rating: 5,
            user: 'Scott',
          },
          {
            headline: 'My resume looks better than ever!',
            review:
              '“My resume looks better than it ever has. I wish it were a bit more useful for general use and it is very tailored for graduate school. I will have to get a new one put together after I graduate but I know I will come to Zipjob to do so!”',
            rating: 4,
            user: 'Chelsea',
          },
          {
            headline: 'BEST investment in my career',
            review:
              '“Best investment in my career. I was paired with a writer that was familiar with my industry. Listened to my work history concerns, and re-wrote my Resume/Cover Letter & Linkedin profile so incredibly well that each time I read it, I feel more confident. My writer did not fabricate, but she did choose the most impressive words to market me to the best of my potential. REALLY WELL WRITEN. This opened many new doors for me.”',
            rating: 5,
            user: 'Grimoires',
          },
          {
            headline: 'My writer was great',
            review:
              "“My writer was great! Answered all my questions no matter how many I sent and always in a timely manner. My resume looks significantly better and it has been getting a lot more attention. Haven't gotten a job yet but I am hopefully that with this resume review I will.”",
            rating: 5,
            user: 'Ciara',
          },
          {
            headline: 'Excellent service',
            review:
              '“In the very first draft I got an excellent draft that did not need any redraft or amendments. I was super chuffed that I had a great writer. She understood my thoughts and got all my concerns covered. I am grateful for giving me the confidence needed to navigate my next career journey”',
            rating: 5,
            user: 'Clara',
          },
          {
            headline: 'Great experience. Highly recommended.',
            review:
              '“This was a great experience and I ended up with a product that was beyond my expectations. I have recommended this service to others and will continue to.”',
            rating: 4,
            user: 'Shawn O.',
          },
        ],
      },
      Scheduler: {
        orderNumber: 'Order # {{orderID}}',
        PreAssignment: {
          header: 'We’re working on matching you with an expert',
          copy: 'Once you’re matched, your expert will send over their availability to meet',
          cta: 'Finding availability',
        },
        Scheduler: {
          header: 'Schedule your session with {{expertName}}',
          headerNoExpert: 'Schedule your session with your Expert',
          back: 'Back',
          meetingDetails: 'Meeting Details',
          meetingTimeFormat: '{{startTime}} - {{endTime}}, {{date}}',
          timeFormat: 'h:mmaaaa',
          dateFormat: 'cccc, MMMM d, yyyy',
          warning:
            'Please double check your meeting time before confirming. We understand that plans change, and allow up to two schedule changes/cancellations with your expert.',
          confirmMeeting: 'Confirm meeting',
          error: 'Could not schedule appointment for that time',
        },
        Scheduled: {
          header:
            'You have a {{sessionType}} with {{expertName}} on {{sessionStart}}',
          copy: 'You’ll be able to join the {{sessionType}} 15 minutes before the start time',
          join: 'Join {{sessionType}}',
          prepare: 'Prepare for your {{sessionType}}',
          dateFormat: "cccc, MMMM d, yyyy 'at' h:mmaaaa",
          reschedule: 'Reschedule {{sessionType}}',
          [ItemTypes.PhoneCall]: 'call',
          [ItemTypes.InterviewPrep]: 'session',
        },
        ScheduledMinimal: {
          header: 'You have a session with {{expertName}} on {{sessionStart}}',
          copy: 'You’ll be able to join the session 15 minutes before the start time',
          dateFormat: "cccc, MMMM d, yyyy 'at' h:mmaaaa",
          reschedule: 'Reschedule session',
        },
        MissedCall: {
          header:
            'You missed your session with {{expertName}} on {{sessionTime}}',
          copy: 'Please reschedule your session and reach out to your expert, if needed.',
          reschedule: 'Reschedule call',
          dateFormat: "cccc, MMMM d, yyyy 'at' h:mmaaaa",
        },
        Completed: {
          header: 'You had a session on {{sessionTime}}',
          copy: 'Experts earn their income from high ratings, leave a rating today',
          buy: 'Buy another session',
          rate: 'Rate your session',
          viewPortfolio: 'View portfolio',
          dateFormat: "EEEE, LLL d 'at' h:mma",
        },
        UnAuthenticated: {
          Completed: {
            header:
              'Your session with {{expertName}} was completed on {{sessionTime}}',
            copy: 'Check your inbox for the next steps!',
            dateFormat: "EEEE, LLL d 'at' h:mma",
          },
        },
        Loading: {
          header: 'We’re working on matching you with an expert',
          copy: 'Once you’re matched, you will be able to schedule your session here',
        },
        Error: {
          header:
            'There was a problem loading your order. Please try again later',
        },
        NoCalendar: {
          header:
            '{{expertName}} is looking for the perfect time for your session',
          copy: 'Once ready, you will be able to schedule your session here',
        },
      },
      Call: {
        StartSession: {
          howToJoin: 'How do you want to join the session?',
          continueOnThisPage: 'Continue on this page',
          continueOnThisPageNote: 'No download or installation required.',
          disabledContinueOnThisPage: 'Cannot continue on this page',
          disabledContinueOnThisPageNote:
            'Your browser does not support this app',
          phone: 'Join by phone',
          phoneNote: '<a>{{country}} {{number}} PIN: {{meetingID}}#</a>',
          app: 'Open your Zoom app',
          appNote: 'Already have it? Go right to your session.',
          timeFormat: 'h:mm a',
          dateFormat: 'cccc, LLLL d, y',
          sessionTime: '{{startTime}} - {{endTime}}, {{date}}',
          defaultItemName: 'Personal Consultation',
        },
      },
      MinimalPageShell: {
        backToHome: 'Back to home',
      },
      ProgressWidget: {
        title: 'Your Success Progress',
        preparednessStatus: {
          [PreparednessStatus.UnderPrepared]: 'Under Prepared',
          [PreparednessStatus.SomewhatPrepared]: 'Somewhat Prepared',
          [PreparednessStatus.UpdateNeeded]: 'Update Needed',
          [PreparednessStatus.FullyPrepared]: 'Fully Prepared',
        },
        cta: {
          headerText: {
            [ItemTypes.LinkedInDocument]:
              'Thought about upgrading your digital brand?',
            [ItemTypes.InterviewPrep]: 'Prepped for Your Next Interview?',
            updateRequired: 'Time for an update',
          },
          bodyText: {
            [ItemTypes.LinkedInDocument]:
              'The first thing many recruiters do after reading your resume is check your Linkedin profile. A professional LinkedIn makeover ensures you’re presenting an eye-catching and cohesive professional presence to hiring managers resulting in increased views and offers.',
            [ItemTypes.InterviewPrep]:
              'Are you ready for the next step? Many of our customers feel underprepared for their upcoming interviews. When you work 1:1 with a TopInterview coach, you’ll enter every interview armed with the right strategies to confidently wow every hiring manager.',
            updateRequired:
              'Career experts recommend updating your career documents every 6 months to maximize opportunities.',
          },
        },
      },
      Product: {
        availableImmediately: 'Available immediately',
        whatsInYourBundle: "What's in your bundle:",
        continueShopping: 'Continue Shopping',
        itemsList: '{{copy}} <b>Includes {{items}}</b>',
        bundles: {
          title: {
            [Bundles.NextStep]: 'Prepare for Your Next Step Bundle',
            [Bundles.AccelerateSearch]: 'Accelerate Your Job Search Bundle',
            [Bundles.DigitallySavvy]: 'Become Digitally Savvy Bundle',
            [Bundles.WinInterview]: 'Win the Interview Bundle',
            [Bundles.WriterExpertise]: 'Writer Expertise Bundle',
            [Bundles.GetWordOut]: 'Get the Word Out Bundle',
            [Bundles.Linkedin]: 'Linkedin Bundle',
            [Bundles.ExpertConnect]: 'Expert Connect Bundle',
          },
          copy: {
            [Bundles.NextStep]:
              'Once you start interviewing, you’ll need more than just your resume to take your job search to the next level. You’ll need to perfect your pitch and ensure you’re confidently prepared for every interview.',
            [Bundles.AccelerateSearch]:
              'Need to expedite your job search? We have the tools to help you work smarter, not harder and find that new job ASAP. In just 48 hours your optimized resume will be ready, posted to top job sites, and off to recruiters to get the ball rolling.',
            [Bundles.DigitallySavvy]:
              'We’re living in a digital world and today’s job search reflects that. Eye-catching LinkedIn profiles and Zoom interviews are major components of today’s search. Are you prepared?',
            [Bundles.WinInterview]:
              'The interview process may just be the most challenging part of the job search. Curveball questions, tough interviewers, you have to confidently be prepared for anything, including the follow-up. We can help you make a lasting impression on each and every interviewer you interact with.',
            [Bundles.WriterExpertise]:
              'Your eye-catching new resume won’t be as effective if you don’t have an optimized, supporting LinkedIn profile and cover letter. You need to be presenting a cohesive professional presence to every prospective employer.',
            [Bundles.GetWordOut]:
              'We have the tools to help you work smarter, not harder and find that new job ASAP. Our two express posting services will help you quickly get your resume posted to the top job sites and then off to recruiters to get the ball rolling.',
            [Bundles.Linkedin]:
              'Your resume and LinkedIn are meant to complement one another and reinforce your candidacy to the recruiter. With 14 million jobs posted on LinkedIn and 97% of recruiters leveraging LinkedIn to find quality talent, you can’t afford to have a half baked profile. While your professional resume writer is working hard to craft a targeted resume that stands out, they could be optimizing your LinkedIn profile in tandem to attract more attention online. Get started today to be sure you’re presenting the total package.',
            [Bundles.ExpertConnect]:
              'We have a huge network of experts well versed in all of today’s job search trends, it’s time to take advantage of their expertise! Gain one-on-one access to both your professional resume writer and a pro interviewer to make sure that your job search goals and elevator pitch are properly presented to every hiring manager.',
          },
        },
        items: {
          copy: {
            [ItemTypes.Guarantee]:
              'If you’re not getting two times as many interviews with your professionally written resume, we’ll write it again one time at no additional charge.',
            [ItemTypes.LinkedInDocument]:
              'With 14 million jobs posted on LinkedIn and 97% of recruiters leveraging LinkedIn to find quality talent, you can’t afford to have a half baked profile.',
            [ItemTypes.PhoneCall]:
              'A call will help add additional context and personalization about your career and goals that communicating via email just can’t',
            [ItemTypes.PersonalConsultationPhoneCall]:
              'A call will help add additional context and personalization about your career and goals that communicating via email just can’t',
            [ItemTypes.PersonalConsultationPhoneCallPreDocument]:
              'A call will help add additional context and personalization about your career and goals that communicating via email just can’t.',
            [ItemTypes.PersonalConsultationPhoneCallPrePostDocument]:
              'Want to chat 1:1 with your writer about your career and goals? A phone call will help add additional context and personalization that communicating via email just can’t. Your writer would love to hear from you.',
            [ItemTypes.PostingService]:
              'Save time job hunting, and get more out of it. ResumeRabbit’s express resume-posting features allow you to post to 50 job sites at once, so you can focus on finding your dream job.',
            [ItemTypes.RecruiterService]:
              'Would you like not just one job recruiter, but thousands, looking out for great career opportunities for you? Recruiter.com does exactly that. It’s an easy, effective way to stay on recruiters’ radar.',
            [ItemTypes.Resume]:
              'An expertly written and keyword-optimized resume refresh that sets you apart.',
            [ItemTypes.RushOrder]:
              'You’ve found the perfect job posting and you need your rewritten resume STAT. Don’t wait, upgrade to our 48 hour rush package and quickly apply with confidence.',
            [ItemTypes.ThankYouLetter]:
              '68% of hiring managers say neglecting to send a thank you note could hurt your candidacy. Save time with a professionally written, customizable note that leaves a lasting impression with the interviewer.',
            [ItemTypes.AdditionalDocumentThankYouLetter]:
              "68% of hiring managers say that neglecting to send a thank you note could jeopardize your candidacy. Don't overlook this simple, yet important part of the interview process. Our professional writers can craft a customizable thank you note that will save you time and help leave a lasting impression with the interviewer.",
            [ItemTypes.AdditionalDocumentFollowUpLetter]:
              "68% of hiring managers say that neglecting to send a thank you note could jeopardize your candidacy. Don't overlook this simple, yet important part of the interview process. Our professional writers can craft a customizable thank you note that will save you time and help leave a lasting impression with the interviewer.",
            [ItemTypes.InterviewPrep]:
              'Interviewing is a skill that can only be mastered with practice. Work 1:1 with a professional interview coach to refine and polish your professional story, giving you the confidence needed to nail every interview.',
            [ItemTypes.CoverLetter]:
              'A professional writer will prepare an expertly-written cover letter that is personalized, targeted, and customizable so that you can adjust when needed and apply to every application with ease.',
          },
        },
      },
    },
    pages: {
      Article1: {
        default: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Process icon',
          text: `<section>
            <p>
              Your {{product}} is an important tool in your job search, and working with a professional {{product}} writer will make you three times as likely to land the job. In fact, it's been proven that a {{product}} written by an expert makes you <a href="//www.topresume.com/career-advice/professional-resume-rewrite-more-hirable" rel="noopener noreferrer" target="_blank">32% more likely</a> to land a job and earn a higher salary. When you get professionally written {{product}}, recruiters will feel more confident presenting you to a hiring manager or client. Your {{product}} will present your professional journey in one compelling career narrative that highlights your value.
            </p>
            <p>
              We’re excited you’ve decided to partner with us for a shorter, better job search. But what is it actually like to work with a professional {{product}} writer? Here, we've outlined the steps of the {{brand}} process so you know what to expect.
            </p>
          </section>
          <section>
            <h2>Getting to know you</h2>
            <p>
              To write your {{product}}, we need to get to know you. We want to know your story —your career history, accomplishments, the ideal outcomes for your job search— so we can best serve you and your goals.
            </p>
            <p>
              Our questionnaire is one tool we use to learn about you, and we appreciate you filling it out with lots of detail. If there’s any additional information you’d like your writer to know, you can send your writer a message using the {{brand}} dashboard. The more we know about how you hope to use your professional documents, the better we can set you up for success.
            </p>
          </section>
          <section>
            <h2>Meet your match</h2>
            <p>
              Our network features thousands of professional writers across a variety of industries and locations. Your questionnaire will give us insight into your industry, goals, and needs, and we'll use that information to match you with the expert best poised to make your career story shine.
            </p>
            <p>
              Once we’ve matched you with a writer, they will message you through the {{brand}} dashboard to introduce themselves. If you have anything to share, you can send a message to your writer at any time.
            </p>
          </section>
          <section>
            <h2>Sit tight</h2>
            <p>
              After you’ve been matched with your writer, they will work their magic, taking a deep dive into your background to create the most successful documents possible. Expect to receive a message in the dashboard that contains your first draft within seven business days of the submission of your questionnaire. If you need your documents faster, click here to learn more about rush orders.
            </p>
            <p>
              In the meantime, you can <a href="//www.topresume.com/career-advice/setting-up-successful-search">check out these tips</a> for what to do while waiting to set you up for a more successful job search.
            </p>
          </section>
          <UpsellBanner />
          <section>
            <h2>Provide feedback</h2>
            <p>
              Because your voice matters, you will have the opportunity to provide feedback for your writer. Through our dashboard, you have a week to work together on your new {{product}}. We recommend providing your revisions in as few rounds as possible to ensure that your writer is able to consider the impact of your requests on your job search strategy as a whole.
            </p>
            <p>
              With this approach, we are able to deliver a {{product}} that will empower you to accomplish your job search goals efficiently. This way, you can quickly get started with the most important thing of all — your job search.
            </p>
          </section>
          <section>
            <h2>Add some special features</h2>
            <p>
              Throughout the process, you'll be given the option to upgrade your order and further boost your job search. You can add a phone consultation with your writer or thank-you letter for when you follow up after an interview, plus extra bonuses like online distribution for your {{product}}. We want to make sure that you have all the tools you need to be successful in your search.
            </p>
          </section>
          <section>
            <h2>Seal the deal</h2>
            <p>
              After collaborating with your writer, the final draft awaits. Download your polished, professional document from the dashboard and get ready for a renewed job search. Your {{product}} is always available for you to access on the {{brand}} dashboard at any time.
            </p>
          </section>
          <section>
            <h2>Land the job</h2>
            <p>
              Armed with a shiny new {{product}}, you're ready to tackle the job search. We wish you the best of luck as you submit your professionally written {{product}} — if you're anything like <a href="//www.topresume.com/career-advice/category/success-stories" rel="noopener noreferrer" target="_blank">our past customers</a>, you'll be signing a contract in no time.
            </p>
          </section>`,
          title: 'What to expect when you work with {{brand}}',
        },
        Zipjob: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Process icon',
          text: `<section>
            <p>
              Your {{product}} is an important tool in your job search, and working with a professional {{product}} writer will make you three times as likely to land the job. In fact, it's been proven that a {{product}} written by an expert makes you <a href="https://www.zipjob.com/blog/hiring-a-resume-writing-service/" rel="noopener noreferrer" target="_blank">32% more likely</a> to land a job and earn a higher salary. When you get professionally written {{product}}, recruiters will feel more confident presenting you to a hiring manager or client. Your {{product}} will present your professional journey in one compelling career narrative that highlights your value.
            </p>
            <p>
              We’re excited you’ve decided to partner with us for a shorter, better job search. But what is it actually like to work with a professional {{product}} writer? Here, we've outlined the steps of the {{brand}} process so you know what to expect.
            </p>
          </section>
          <section>
            <h2>Getting to know you</h2>
            <p>
              To write your {{product}}, we need to get to know you. We want to know your story —your career history, accomplishments, the ideal outcomes for your job search— so we can best serve you and your goals.
            </p>
            <p>
              Our questionnaire is one tool we use to learn about you, and we appreciate you filling it out with lots of detail. If there’s any additional information you’d like your writer to know, you can send your writer a message using the {{brand}} dashboard. The more we know about how you hope to use your professional documents, the better we can set you up for success.
            </p>
          </section>
          <section>
            <h2>Meet your match</h2>
            <p>
              Our network features thousands of professional writers across a variety of industries and locations. Your questionnaire will give us insight into your industry, goals, and needs, and we'll use that information to match you with the expert best poised to make your career story shine.
            </p>
            <p>
              Once we’ve matched you with a writer, they will message you through the {{brand}} dashboard to introduce themselves. If you have anything to share, you can send a message to your writer at any time.
            </p>
          </section>
          <section>
            <h2>Sit tight</h2>
            <p>
              After you’ve been matched with your writer, they will work their magic, taking a deep dive into your background to create the most successful documents possible. Expect to receive a message in the dashboard that contains your first draft within seven business days of the submission of your questionnaire. If you need your documents faster, click here to learn more about rush orders.
            </p>
            <p>
              In the meantime, you can <a href="/setting-the-stage-for-a-successful-search">check out these tips</a> for what to do while waiting to set you up for a more successful job search.
            </p>
          </section>
          <UpsellBanner />
          <section>
            <h2>Provide feedback</h2>
            <p>
              Because your voice matters, you will have the opportunity to provide feedback for your writer. Through our dashboard, you have a week to work together on your new {{product}}. We recommend providing your revisions in as few rounds as possible to ensure that your writer is able to consider the impact of your requests on your job search strategy as a whole.
            </p>
            <p>
              With this approach, we are able to deliver a {{product}} that will empower you to accomplish your job search goals efficiently. This way, you can quickly get started with the most important thing of all — your job search.
            </p>
          </section>
          <section>
            <h2>Add some special features</h2>
            <p>
              Throughout the process, you'll be given the option to upgrade your order and further boost your job search. You can add a phone consultation with your writer or thank-you letter for when you follow up after an interview, plus extra bonuses like online distribution for your {{product}}. We want to make sure that you have all the tools you need to be successful in your search.
            </p>
          </section>
          <section>
            <h2>Seal the deal</h2>
            <p>
              After collaborating with your writer, the final draft awaits. Download your polished, professional document from the dashboard and get ready for a renewed job search. Your {{product}} is always available for you to access on the {{brand}} dashboard at any time.
            </p>
          </section>
          <section>
            <h2>Land the job</h2>
            <p>
              Armed with a shiny new {{product}}, you're ready to tackle the job search. We wish you the best of luck as you submit your professionally written {{product}} — if you're anything like <a href="https://www.zipjob.com/zipjob-reviews/" rel="noopener noreferrer" target="_blank">our past customers</a>, you'll be signing a contract in no time.
            </p>
          </section>`,
          title: 'What to expect when you work with {{brand}}',
        },
      },
      Article2: {
        default: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Get to know icon',
          quote1: {
            author: 'Dominic',
            quote:
              'Great job done by Thomas. Took my old {{product}} and made it POP. When I sent out my new {{product}} to my recruiters they actually replied back with "Nice looking {{product}}". Or who did this {{product}} for you? I want to use them also.',
            title:
              'Working with {{brand}}, you’ll get compliments on your {{product}}',
          },
          quote2: {
            author: 'Amanda S.',
            quote:
              'I really appreciate the level of detail Tracy put forth. You could tell she did some research with regards to my employers, took my concerns/suggestions into consideration and focused on what I want to direct my career towards. The end result was stellar. At first I thought, who is this person (me). Then I realized these are all things I’ve done over my career and have been selling myself short. I wish I would have made this investment sooner, I truly feel I would have been further in my career if I had. Thank you',
            title: 'Our writers sweat the details so you don’t have to',
          },
          quote3: {
            author: 'Kim K.',
            quote:
              'My {{product}} writer, Ryan, was incredibly helpful, responsive, professional and empathetic. He literally transformed my {{product}} from what I felt was an ugly duckling into a beautiful swan. I feel 100% more confident in my job search as a result of Ryan’s help. Thank you, {{brand}}!',
            title:
              'Our writers have been in your shoes and understand the process can be stressful...',
          },
          quote4: {
            author: 'Kyle M.',
            quote:
              'I worked with Michelle, and she did an excellent job on my Cover Letter, {{product}}, thank you letter and LinkedIn account! We finished ahead of schedule and I now have an interview in less than a week after it was completed!',
            title:
              '...which is why they will do everything they can to help shorten your job search',
          },
          text: `<section>
            <p>
              When it comes to {{product}} writing, we at {{brand}} know what we're doing — and that's all thanks to our writers.
            </p>
            <p>
              At {{brand}} we have a worldwide network of career experts that come from a variety of backgrounds. The diverse experiences of our writers allows us to match you with a writer that will know the ins and outs of your profession. Many of our writers come from professional backgrounds just like yours, who now apply that experience to helping others.
            </p>
            <p>
              Our expert {{product}} writers not only edit and rewrite your {{product}}, but they also help you take hold of your career narrative. It's not just about adding a comma, spicing it up with a few keywords, or moving around certain parts of your {{product}}; it's about creating a cohesive career story that will help you land your dream job faster.
            </p>
            <p>
              So who exactly are these {{brand}} writers? We'll let them introduce themselves and tell you more about their work.
            </p>
            </section>
            <section>
              <ContentVideo />
            </section>
            <section>
            <h2>Our five-star writers can transform your {{product}}</h2>
            <p>
              You don’t have to just take our word for it, however — what do our past customers have to say? Below, find some first-hand accounts of what it’s like to work with our writers.
            </p>
          </section>
          <section>
            <Quotes />
          <section>
            <h2>We can’t wait to work with you</h2>
            <p>
              We’re so excited for you to get started and work with our writers, too. We’re confident that you’ll have a great experience, just as our past customers have.
            </p>
            <p>
              Curious about what comes next? Check out <a href="/collaborating-with-your-writer">Collaborating With Your Writer</a> to learn more about working together with your writer, and what you can do to ensure an outstanding final {{product}}.
            </p>
          </section>`,
          title: 'Getting to know our writers',
        },
        Zipjob: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Get to know icon',
          quote1: {
            author: 'Dominic',
            quote:
              'Great job done by Thomas. Took my old {{product}} and made it POP. When I sent out my new {{product}} to my recruiters they actually replied back with "Nice looking {{product}}". Or who did this {{product}} for you? I want to use them also.',
            title:
              'Working with {{brand}}, you’ll get compliments on your {{product}}',
          },
          quote2: {
            author: 'Amanda S.',
            quote:
              'I really appreciate the level of detail Tracy put forth. You could tell she did some research with regards to my employers, took my concerns/suggestions into consideration and focused on what I want to direct my career towards. The end result was stellar. At first I thought, who is this person (me). Then I realized these are all things I’ve done over my career and have been selling myself short. I wish I would have made this investment sooner, I truly feel I would have been further in my career if I had. Thank you',
            title: 'Our writers sweat the details so you don’t have to',
          },
          quote3: {
            author: 'Kim K.',
            quote:
              'My {{product}} writer, Ryan, was incredibly helpful, responsive, professional and empathetic. He literally transformed my {{product}} from what I felt was an ugly duckling into a beautiful swan. I feel 100% more confident in my job search as a result of Ryan’s help. Thank you, {{brand}}!',
            title:
              'Our writers have been in your shoes and understand the process can be stressful...',
          },
          quote4: {
            author: 'Kyle M.',
            quote:
              'I worked with Michelle, and she did an excellent job on my Cover Letter, {{product}}, thank you letter and LinkedIn account! We finished ahead of schedule and I now have an interview in less than a week after it was completed!',
            title:
              '...which is why they will do everything they can to help shorten your job search',
          },
          text: `<section>
            <p>
              When it comes to {{product}} writing, we at {{brand}} know what we're doing — and that's all thanks to our writers.
            </p>
            <p>
              At {{brand}} we have a worldwide network of career experts that come from a variety of backgrounds. The diverse experiences of our writers allows us to match you with a writer that will know the ins and outs of your profession. Many of our writers come from professional backgrounds just like yours, who now apply that experience to helping others.
            </p>
            <p>
              Our expert {{product}} writers not only edit and rewrite your {{product}}, but they also help you take hold of your career narrative. It's not just about adding a comma, spicing it up with a few keywords, or moving around certain parts of your {{product}}; it's about creating a cohesive career story that will help you land your dream job faster.
            </p>
            </section>
            <section>
            <h2>Our five-star writers can transform your {{product}}</h2>
            <p>
              You don’t have to just take our word for it, however — what do our past customers have to say? Below, find some first-hand accounts of what it’s like to work with our writers.
            </p>
          </section>
          <section>
            <Quotes />
          <section>
            <h2>We can’t wait to work with you</h2>
            <p>
              We’re so excited for you to get started and work with our writers, too. We’re confident that you’ll have a great experience, just as our past customers have.
            </p>
            <p>
              Curious about what comes next? Check out <a href="/collaborating-with-your-writer">Collaborating With Your Writer</a> to learn more about working together with your writer, and what you can do to ensure an outstanding final {{product}}.
            </p>
          </section>`,
          title: 'Getting to know our writers',
        },
      },
      Article3: {
        default: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Setting the stage icon',
          text: `<section>
            <h2>Your new {{product}} is on the way</h2>
            <p>
              We’re thrilled to have you join the 500,000 other job seekers we’ve helped find their next step. Thanks to our long history of helping job seekers, we’ve learned a thing or two about how to be successful in your job search.
            </p>
            <p>
              You can expect a world-class {{product}} at the end of the {{brand}} process. Our writers are fantastic — and we make them even better, teaching them all of the tips, tricks, and secrets we’ve learned along the way.
            </p>
            <p>
              We promote tested tactics that get results. No detail is too small when we are working on your {{product}}. The fonts, character spacing, and layouts have all been tested extensively. They are designed to be irresistible to recruiters and hiring managers while also passing through any automated <a href="//www.topresume.com/career-advice/what-is-an-ats-resume" rel="noopener noreferrer" target="_blank">applicant tracking system</a> that you encounter during your job search.
            </p>
            <p>
              Sometimes our known best practices go against what you might
              expect. For example, <a href="//www.topresume.com/career-advice/explained-cut-resume-length" rel="noopener noreferrer" target="_blank">the ideal {{product}} length is two pages</a>— not one, not three. To that end, we might remove content from your existing {{product}} or work to fill in the blanks to help achieve the ideal for you.
            </p>
            <p>
              We’re so excited to be your partner on your career journey, and want to make sure you’re set up for success. While your writer is working, there are steps you can take to ensure that you’re ready to hit the ground running when your final draft is delivered.
            </p>
          </section>
          <section>
            <h2>What to do while you wait</h2>
            <p>
              When you're waiting to receive the first draft of your professionally written {{product}}, it can feel like an eternity — especially when you're itching to land your next job.
            </p>
            <p>
              However, don't consider this time to be a waste. While a well-crafted {{product}} and cover letter are key to a successful search, they're only one piece of the job-search jigsaw puzzle.
            </p>
            <p>
              To land the right job as soon as possible, there are a lot of things you'll need to do before you can start applying for jobs. Use this valuable time to complete the action items below so you can hit the ground running as soon as you've approved the final draft of your {{product}}.
            </p>
            <ul>
              <li>
                <a href="#section1">Write down your goals</a>
              </li>
              <li>
                <a href="#section2">Create a target company list</a>
              </li>
              <li>
                <a href="#section3">Get organized</a>
              </li>
              <li>
                <a href="#section4">Audit your online presence</a>
              </li>
              <li>
                <a href="#section5">Earmark jobs that interest you</a>
              </li>
              <li>
                <a href="#section6">Prep for job applications</a>
              </li>
              <li>
                <a href="#section7">Prioritize networking</a>
              </li>
              <li>
                <a href="#section8">Line up references</a>
              </li>
              <li>
                <a href="#section9">Practice interviewing</a>
              </li>
              <li>
                <a href="#section10">Take some time for yourself</a>
              </li>
            </ul>
          </section>
          <section id="section1">
            <h2>Write down your goals</h2>
            <p>
              Be clear on the <a href="//www.topresume.com/career-advice/ask-amanda-how-do-i-find-my-dream-job" rel="noopener noreferrer" target="_blank">type of job you're seeking</a> and write it down. You are 10 times more likely to accomplish your goal when you do this. Not only that, but this information will influence many other aspects of your search, from the types of employers you target to how you prioritize your networking activities. Consider all aspects of the job, from the work environment and industry to your commute and desired salary. The better defined your goals, the more efficient your job search will be.
            </p>
          </section>
          <section id="section2">
            <h2>Create a target company list</h2>
            <p>
              Landing a new job often requires you to pursue multiple job opportunities at the same time. To streamline the process, it's helpful to build a list of companies that meet your location, industry, size, and corporate-culture needs. Use the information you brainstormed above to create your <a href="//www.topresume.com/career-advice/create-target-company-list-for-job-search" rel="noopener noreferrer" target="_blank">target list of employers.</a>
            </p>
          </section>
          <UpsellBanner />
          <section id="section3">
            <h2>Get organized</h2>
            <p>
              Take a little time to figure out the best way for you to keep your
              job-search activities organized:
            </p>
            <ul>
              <li>
                <strong>Communication</strong>: Consider creating a professional-looking, new email address with Gmail that's reserved for your job-search activities. Also, if you decide to <a href="//www.topresume.com/career-advice/update-your-gmail-picture" rel="noopener noreferrer" target="_blank" >upload a picture to your email account</a>, make sure it's appropriate for your job search. Revisit the voicemail message on your cell phone, as well, to confirm that it includes your name so employers know they've called the right person.
              </li>
              <li>
                <strong>Job applications</strong>: Whether you're interested in a web-based solution like <a href="//huntr.co/" rel="noopener noreferrer" target="_blank">Huntr</a> or <a href="//gojobhero.com/" rel="noopener noreferrer" target="_blank">JobHero</a>, or you prefer to go old school with an Excel sheet or Word document, it's important to find a way to track your job applications and other job-search activities. Keep track of the company name, job title and location, job listing URL, where you found the job, the point of contact (if applicable), the date you applied, and the name of the {{product}} file you used for the application. As you start applying for jobs, be sure to document the date you followed up on your application and if you secured an interview.
              </li>
              <li>
                <strong>Job boards</strong>: Run some Google searches to uncover niche job boards in your field or industry (e.g. marketing job boards). Make a list of all the job boards you'd like to use for your job search and start creating your profile on each so all you have left to do is upload your new resume.
              </li>
            </ul>
            <p>
              In addition, <a href="//www.topresume.com/career-advice/job-search-app" rel="noopener noreferrer"target="_blank">upload apps to your smart device</a> that will help you stay organized and search on the go.
            </p>
          </section>
          <section id="section4">
            <h2>Audit your online presence</h2>
            <p>
              Over 90 percent of recruiters admit to looking at candidates' online profiles — regardless of whether the candidates provided this information. If you're not actively managing your online presence, you may be hurting your chances of landing the job. <a href="//www.topresume.com/career-advice/monitor-online-brand-with-google-search">Google your name</a> to get a sense of what employers will find when they search for you and set up Google Alerts for your name to actively monitor it going forward.
            </p>
            <p>
              Then, deactivate or update any profiles you have on job boards, social media platforms, online directories, and so forth to ensure employers will find the same professional online that they will meet face-to-face. Finally, increase the security settings on personal accounts you don't want employers to find.
            </p>
          </section>
          <section id="section5">
            <h2>Earmark jobs that interest you</h2>
            <p>
              Start searching for job opportunities online that you're
              interested in and qualified to do.
                <span>
                  Before you add a job to your list, <a href="https://www.topresume.com/career-advice/ask-yourself-questions-before-applying-job" rel="noopener noreferrer" target="_blank">ask yourself these questions</a> to make sure the job application will be worthwhile.
                </span>
            </p>
            <p>
              In addition to adding the job to whatever system you've chosen to organize your applications, be sure to save a copy of the job description in a Word or Google document so you have it for future reference.
            </p>
            <p>
              Oftentimes, a company will take down its job posting once it's received enough applicants, so you may not have access to this information when it's time to prepare for the interview.
            </p>
          </section>
          <section id="section6">
            <h2>Prep for your job applications</h2>
            <p>
              {{product}} – and cover letters – work best when they are tailored to specific job openings or employers. While your professionally written {{product}} will be in great shape, you'll still need to make minor tweaks to the keywords to customize it for a specific job listing.
            </p>
            <p>
              These small optimizations will increase your application's chances of getting past the hiring bots and on to someone in HR for review.
            </p>
            <p>
              Jot down the keywords that are used repeatedly in the job description, and make a note of any skills or qualifications that seem very important to the employer. That way, you can be sure to highlight this information in your cover letter and {{product}} when the time comes.
            </p>
            <p>
              Also, if there isn't a name associated with the job listing, do a little online sleuthing to uncover the name of the hiring manager or recruiter responsible for the role. That way, you can properly address your cover letter.
            </p>
          </section>
          <section id="section7">
            <h2>Make networking a priority</h2>
            <p>
              There's no denying the power a strong professional network can have over your career success — especially when you're looking for work. In fact, you're 10 times more likely to land a job when your application is accompanied by a referral. Use this time to ramp up your networking efforts
            </p>
            <p>
              Take a second look at your existing contacts and prioritize them based on their connection to the companies, fields, and industries that interest you. Start reconnecting with these people and setting up coffee dates and phone calls to <a href="//www.topresume.com/career-advice/asking-network-for-job-search-help" rel="noopener noreferrer" target="_blank">ask for help with your job search</a>.
            </p>
            <p>Also, explore new professional associations to join or events to attend that sound interesting and are relevant to your goals.</p>
          </section>
          <section id="section8">
            <h2>Line up references</h2>
            <p>
              <a href="//www.topresume.com/career-advice/ask-amanda-professional-references-job-search" rel="noopener noreferrer" target="_blank">References may not belong on your {{product}}</a>, but they play an important role in your job search. Line up at least three references from people with whom you currently or previously worked. The idea is to identify people who have insight into your skills and capabilities and who you trust to say good things about you and your performance.
            </p>
            <p>
              In other words, target people who are willing to advocate for your candidacy. Then, contact these people and make sure they're willing to be a reference for you when the time comes. Once your professional {{product}} is ready, be sure to send each of your references a copy so they have a better sense of your current goals and qualifications.
            </p>
          </section>
          <section id="section9">
            <h2>Work on your interview skills</h2>
            <p>
              We know that professionally written {{product}} help you land interviews quickly, so it’s never too early to start sharpening your skills for when you get to the face-to-face portion of your job search.
            </p>
            <p>
              It’s the rare individual that doesn’t get nervous when interviewing for a job — and it’s the last place you want to be nervous, given that <a href="//www.topinterview.com/interview-advice/art-of-the-interview-fear-to-fun" rel="noopener noreferrer" target="_blank">many interviewers will decide almost immediately if they want to hire you</a>. Practicing your elevator pitch and the job interview questions that you know might trip you up can go a long way towards improving your interviewing skills and confidence.
            </p>
            <p>
              You may also want to <a href="//www.topinterview.com/" rel="noopener noreferrer" target="_blank">work one on one with an interview coach</a> to improve your interview skills. When you work with an interview coach you will receive guidance and feedback to ensure that the time and effort you put into your interview preparation will actually benefit you.
            </p>
            <p>
              With an interview coach, you won’t waste your time. Instead, you’ll get coaching targeted towards your weaknesses and tailored to your industry.
            </p>
          </section>
          <section id="section10">
            <h2>Take some time for you</h2>
            <p>
              Finally, we understand that the job search is emotionally draining. Maintain a positive attitude, don't give up, and be patient and kind to yourself.
            </p>
            <p>
              While there’s a lot you can do to advance your job search while you wait for your {{product}}, there’s no shame in sitting back and letting us handle the work for now. We’re your partner in job search success — we’re working for you so you can breathe easy.
            </p>
            <p>
              And if you need us at any time, you can always send us a message via our chat tool.
            </p>
          </section>`,
          title: 'Setting the stage for a successful search',
        },
        Zipjob: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Setting the stage icon',
          text: `<section>
            <h2>Your new {{product}} is on the way</h2>
            <p>
              We’re thrilled to have you join the 500,000 other job seekers we’ve helped find their next step. Thanks to our long history of helping job seekers, we’ve learned a thing or two about how to be successful in your job search.
            </p>
            <p>
              You can expect a world-class {{product}} at the end of the {{brand}} process. Our writers are fantastic — and we make them even better, teaching them all of the tips, tricks, and secrets we’ve learned along the way.
            </p>
            <p>
              We promote tested tactics that get results. No detail is too small when we are working on your {{product}}. The fonts, character spacing, and layouts have all been tested extensively. They are designed to be irresistible to recruiters and hiring managers while also passing through any automated <a href="https://www.zipjob.com/blog/get-your-resume-past-applicant-tracking-systems/" rel="noopener noreferrer" target="_blank">applicant tracking system</a> that you encounter during your job search.
            </p>
            <p>
              Sometimes our known best practices go against what you might
              expect. For example, <a href="https://www.zipjob.com/blog/resume-three-pages-or-longer/" rel="noopener noreferrer" target="_blank">the ideal {{product}} length is two pages</a>— not one, not three. To that end, we might remove content from your existing {{product}} or work to fill in the blanks to help achieve the ideal for you.
            </p>
            <p>
              We’re so excited to be your partner on your career journey, and want to make sure you’re set up for success. While your writer is working, there are steps you can take to ensure that you’re ready to hit the ground running when your final draft is delivered.
            </p>
          </section>
          <section>
            <h2>What to do while you wait</h2>
            <p>
              When you're waiting to receive the first draft of your professionally written {{product}}, it can feel like an eternity — especially when you're itching to land your next job.
            </p>
            <p>
              However, don't consider this time to be a waste. While a well-crafted {{product}} and cover letter are key to a successful search, they're only one piece of the job-search jigsaw puzzle.
            </p>
            <p>
              To land the right job as soon as possible, there are a lot of things you'll need to do before you can start applying for jobs. Use this valuable time to complete the action items below so you can hit the ground running as soon as you've approved the final draft of your {{product}}.
            </p>
            <ul>
              <li>
                <a href="#section1">Write down your goals</a>
              </li>
              <li>
                <a href="#section2">Create a target company list</a>
              </li>
              <li>
                <a href="#section3">Get organized</a>
              </li>
              <li>
                <a href="#section4">Audit your online presence</a>
              </li>
              <li>
                <a href="#section5">Earmark jobs that interest you</a>
              </li>
              <li>
                <a href="#section6">Prep for job applications</a>
              </li>
              <li>
                <a href="#section7">Prioritize networking</a>
              </li>
              <li>
                <a href="#section8">Line up references</a>
              </li>
              <li>
                <a href="#section9">Practice interviewing</a>
              </li>
              <li>
                <a href="#section10">Take some time for yourself</a>
              </li>
            </ul>
          </section>
          <section id="section1">
            <h2>Write down your goals</h2>
            <p>
              Be clear on the <a href="https://www.zipjob.com/blog/how-to-look-for-jobs/" rel="noopener noreferrer" target="_blank">type of job you're seeking</a> and write it down. You are 10 times more likely to accomplish your goal when you do this. Not only that, but this information will influence many other aspects of your search, from the types of employers you target to how you prioritize your networking activities. Consider all aspects of the job, from the work environment and industry to your commute and desired salary. The better defined your goals, the more efficient your job search will be.
            </p>
          </section>
          <section id="section2">
            <h2>Create a target company list</h2>
            <p>
              Landing a new job often requires you to pursue multiple job opportunities at the same time. To streamline the process, it's helpful to build a list of companies that meet your location, industry, size, and corporate-culture needs. Use the information you brainstormed above to create your <a href="//www.topresume.com/career-advice/create-target-company-list-for-job-search" rel="noopener noreferrer" target="_blank">target list of employers.</a>
            </p>
          </section>
          <UpsellBanner />
          <section id="section3">
            <h2>Get organized</h2>
            <p>
              Take a little time to figure out the best way for you to keep your
              job-search activities organized:
            </p>
            <ul>
              <li>
                <strong>Communication</strong>: Consider creating a professional-looking, new email address with Gmail that's reserved for your job-search activities. Also, if you decide to <a href="https://www.zipjob.com/blog/24-linkedin-profile-tips-to-make-you-stand-out-in-2023/" rel="noopener noreferrer" target="_blank" >upload a picture to your email account</a>, make sure it's appropriate for your job search. Revisit the voicemail message on your cell phone, as well, to confirm that it includes your name so employers know they've called the right person.
              </li>
              <li>
                <strong>Job applications</strong>: Whether you're interested in a web-based solution like <a href="//huntr.co/" rel="noopener noreferrer" target="_blank">Huntr</a> or <a href="//gojobhero.com/" rel="noopener noreferrer" target="_blank">JobHero</a>, or you prefer to go old school with an Excel sheet or Word document, it's important to find a way to track your job applications and other job-search activities. Keep track of the company name, job title and location, job listing URL, where you found the job, the point of contact (if applicable), the date you applied, and the name of the {{product}} file you used for the application. As you start applying for jobs, be sure to document the date you followed up on your application and if you secured an interview.
              </li>
              <li>
                <strong>Job boards</strong>: Run some Google searches to uncover niche job boards in your field or industry (e.g. marketing job boards). Make a list of all the job boards you'd like to use for your job search and start creating your profile on each so all you have left to do is upload your new resume.
              </li>
            </ul>
          </section>
          <section id="section4">
            <h2>Audit your online presence</h2>
            <p>
              Over 90 percent of recruiters admit to looking at candidates' online profiles — regardless of whether the candidates provided this information. If you're not actively managing your online presence, you may be hurting your chances of landing the job. <a href="//www.topresume.com/career-advice/monitor-online-brand-with-google-search">Google your name</a> to get a sense of what employers will find when they search for you and set up Google Alerts for your name to actively monitor it going forward.
            </p>
            <p>
              Then, deactivate or update any profiles you have on job boards, social media platforms, online directories, and so forth to ensure employers will find the same professional online that they will meet face-to-face. Finally, increase the security settings on personal accounts you don't want employers to find.
            </p>
          </section>
          <section id="section5">
            <h2>Earmark jobs that interest you</h2>
            <p>
              Start searching for job opportunities online that you're
              interested in and qualified to do.
                <span>
                  Before you add a job to your list, <a href="https://www.topresume.com/career-advice/ask-yourself-questions-before-applying-job" rel="noopener noreferrer" target="_blank">ask yourself these questions</a> to make sure the job application will be worthwhile.
                </span>
            </p>
            <p>
              In addition to adding the job to whatever system you've chosen to organize your applications, be sure to save a copy of the job description in a Word or Google document so you have it for future reference.
            </p>
            <p>
              Oftentimes, a company will take down its job posting once it's received enough applicants, so you may not have access to this information when it's time to prepare for the interview.
            </p>
          </section>
          <section id="section6">
            <h2>Prep for your job applications</h2>
            <p>
              {{product}} – and cover letters – work best when they are tailored to specific job openings or employers. While your professionally written {{product}} will be in great shape, you'll still need to make minor tweaks to the keywords to customize it for a specific job listing.
            </p>
            <p>
              These small optimizations will increase your application's chances of getting past the hiring bots and on to someone in HR for review.
            </p>
            <p>
              Jot down the keywords that are used repeatedly in the job description, and make a note of any skills or qualifications that seem very important to the employer. That way, you can be sure to highlight this information in your cover letter and {{product}} when the time comes.
            </p>
            <p>
              Also, if there isn't a name associated with the job listing, do a little online sleuthing to uncover the name of the hiring manager or recruiter responsible for the role. That way, you can properly address your cover letter.
            </p>
          </section>
          <section id="section7">
            <h2>Make networking a priority</h2>
            <p>
              There's no denying the power a strong professional network can have over your career success — especially when you're looking for work. In fact, you're 10 times more likely to land a job when your application is accompanied by a referral. Use this time to ramp up your networking efforts
            </p>
            <p>
              Take a second look at your existing contacts and prioritize them based on their connection to the companies, fields, and industries that interest you. Start reconnecting with these people and setting up coffee dates and phone calls to <a href="https://www.zipjob.com/blog/how-to-ask-for-a-reference/" rel="noopener noreferrer" target="_blank">ask for help with your job search</a>.
            </p>
            <p>Also, explore new professional associations to join or events to attend that sound interesting and are relevant to your goals.</p>
          </section>
          <section id="section8">
            <h2>Line up references</h2>
            <p>
              <a href="https://www.zipjob.com/blog/what-not-to-include-on-your-resume-quick-guide/" rel="noopener noreferrer" target="_blank">References may not belong on your {{product}}</a>, but they play an important role in your job search. Line up at least three references from people with whom you currently or previously worked. The idea is to identify people who have insight into your skills and capabilities and who you trust to say good things about you and your performance.
            </p>
            <p>
              In other words, target people who are willing to advocate for your candidacy. Then, contact these people and make sure they're willing to be a reference for you when the time comes. Once your professional {{product}} is ready, be sure to send each of your references a copy so they have a better sense of your current goals and qualifications.
            </p>
          </section>
          <section id="section9">
            <h2>Work on your interview skills</h2>
            <p>
              We know that professionally written {{product}} help you land interviews quickly, so it’s never too early to start sharpening your skills for when you get to the face-to-face portion of your job search.
            </p>
            <p>
              It’s the rare individual that doesn’t get nervous when interviewing for a job — and it’s the last place you want to be nervous, given that <a href="//www.topinterview.com/interview-advice/art-of-the-interview-fear-to-fun" rel="noopener noreferrer" target="_blank">many interviewers will decide almost immediately if they want to hire you</a>. Practicing your elevator pitch and the job interview questions that you know might trip you up can go a long way towards improving your interviewing skills and confidence.
            </p>
            <p>
              You may also want to <a href="//www.topinterview.com/" rel="noopener noreferrer" target="_blank">work one on one with an interview coach</a> to improve your interview skills. When you work with an interview coach you will receive guidance and feedback to ensure that the time and effort you put into your interview preparation will actually benefit you.
            </p>
            <p>
              With an interview coach, you won’t waste your time. Instead, you’ll get coaching targeted towards your weaknesses and tailored to your industry.
            </p>
          </section>
          <section id="section10">
            <h2>Take some time for you</h2>
            <p>
              Finally, we understand that the job search is emotionally draining. Maintain a positive attitude, don't give up, and be patient and kind to yourself.
            </p>
            <p>
              While there’s a lot you can do to advance your job search while you wait for your {{product}}, there’s no shame in sitting back and letting us handle the work for now. We’re your partner in job search success — we’re working for you so you can breathe easy.
            </p>
            <p>
              And if you need us at any time, you can always send us a message via our chat tool.
            </p>
          </section>`,
          title: 'Setting the stage for a successful search',
        },
      },
      Article4: {
        default: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Collaborating icon',
          text: `<section>
            <h2>Your success is our success</h2>
            <p>
              As a job seeker, the details matter. When you’re applying for a
              job, the picture your {{product}} paints of you is often all a
              hiring manager has to form a first impression. And with the advent
              of applicant tracking technology, if your {{product}} is missing a
              crucial keyword, you’re at risk of getting passed over completely.
            </p>
            <p>
              We are invested in your success, and we want to make sure that
              anyone reviewing your professional materials sees a masterpiece.
              Your close collaboration with your writer ensures that you receive
              the best possible product for your job search.
            </p>
            <p>
              Here, we’ll share some tips on how we can work together to deliver
              the best service to you.
            </p>
          </section>
          <section>
            <h2>We like it when you overshare</h2>
            <p>
              Sharing your career goal with us is critical to receiving a
              finished {{product}} that will help you achieve it. We want to know
              everything, no detail is too small, so we can incorporate that
              information into the final product.
            </p>
            <p>
              You can start sharing what’s important to you as soon as you’ve
              purchased with us — you don’t need to wait until you’ve been
              matched with a writer.
            </p>
            <h3>
              Keep us focused on the right next step for you
            </h3>
            <p>
              Sometimes, our onboarding process doesn’t capture your single most
              important career next step with us — the questionnaire can capture
              a number of different career options. Help us stay focused on your
              most important career goal.
            </p>
            <h3>Don’t forget to brag</h3>
            <p>
              The job search isn’t the time to be modest. Feel free to share
              supporting materials, such as awards, certificates, and
              references, with us — the earlier in the process, the better!
              Remember, you can upload any documentation to the {{brand}}
              portal as soon as you start working with us, even before you
              receive your first draft.
            </p>
            <h3>No detail is too small</h3>
            <p>
              The more details you can provide about who you are and what you
              want to achieve, the better. Don’t hesitate to communicate with
              us. We want to hear from you, and you can start sharing with us
              immediately — you don’t have to wait until there’s been a writer
              match.
            </p>
          </section>
          <UpsellBanner />
          <section>
            <h3>Our week of working together</h3>
            <p>
              You’ve told us about yourself, you’ve been matched with a writer,
              and now you have a first draft ready for your review. Once you
              have your first draft, you and your writer have a week — seven
              days — to put the finishing touches on your documents. If there’s
              any point during the week where you feel like your needs aren’t
              being met, you should contact us and let us know so we can make it
              right.
            </p>
            <p>
              In our experience, a week is the perfect length of time to get it
              right. It allows you and your writer to revise your {{product}}
              together, but isn’t so long that it prevents you from putting your
              new {{product}} to work.
            </p>
            <p>
              At the end of the day, we want you to be able to use your
              documents to apply for jobs as quickly as possible!
            </p>
            <h3>Need more time?</h3>
            <p>
              That said, we know that not everyone’s circumstances are identical
              — what works for most may not work for you.
            </p>
            <p>
              For example, if your career goal changes or you simply find that
              you don’t have time to to dedicate to your project over the seven
              days, you can continue to collaborate on your {{product}} with your
              writer on more revisions for an additional charge. We want to give
              everyone amazing service, and keeping the revision window time
              bound ensures all of our customers get the help they need.
            </p>
            <p>
              If you need to put the entire process on hold, let us know. It can
              be challenging for us to stop the writing process, but we will
              always work with you to find a solution that works for everyone.
            </p>
            <h3>Final approval</h3>
            <p>
              As soon as you’re happy with your {{product}}, you can approve your
              documents and receive a download of the final copy. Once you’re
              ready to start using your {{product}}, we’re here to help you use
              it, too.
            </p>
            <p>
              Our article “Taking the Next Step” is a great place to start
              learning about how to use your expertly written {{product}} for job
              search success
            </p>
          </section>
          <section>
            <h2>
              Our writers would love to hear from you
            </h2>
            <p>
              Once you have your finished {{product}}, we would love to get your
              feedback on the experience and for you to share us with your
              friends.
            </p>
            <p>
              Our writers love to hear what you think — a great review for your
              writer is the equivalent of giving a great tip to your waiter or
              waitress at a restaurant. Your comments and reflections on the
              {{product}} writing process help us improve, too.
            </p>
          </section>`,
          title: 'Collaborating with Your Writer',
        },
      },
      Article5: {
        default: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Next step icon',
          text: `<section>
            <h2>My {{product}} is done. What’s next?</h2>
            <p>
              You’ve shared your career goal with us, you’ve collaborated with your writer, and now your {{product}} is ready for the job search.
            </p>
            <p>
              When you’re happy with your {{product}}, you can approve the document at any time, and the finished file will always be available for you to access on your {{brand}} dashboard.
            </p>
            <p>
              Now you’re ready to take the next step: conquering the job hunt and securing your next position.
            </p>
            <p>
              Hunting for a job doesn't have to be overwhelming. We’ve broken down the job search into steps that will help make the experience as pain-free as possible.
            </p>
          </section>
          <section id="section1">
            <h2>The job search</h2>
            <p>
              Amassing job leads is the name of the game when you're in active job-search mode, but solely applying for jobs online isn't going to cut it if you want to be successful.
            </p>
            <p>
              Instead, our career expert, Amanda Augustine, suggests what she calls “harnessing the 'power of three.'” Come again?
            </p>
            <p>
              Essentially, the most successful job seekers are those who use multiple methods (the three below) to search for opportunities.
            </p>
            <p>
              Combine their powers, and you'll maximize the number of leads you can pursue, which may very well maximize your success.
            </p>
            <section id="section1a">
              <h3>Applications and follow-up</h3>
              <p>
                Searching online, applying online, application after application… it can get exhausting, and it's hard to know if the jobs you're applying to are even <a href="//www.topresume.com/career-advice/ask-yourself-questions-before-applying-job" rel="noopener noreferrer" target="_blank">worth the effort</a>. And then you find a job listing that seems, for lack of a better word, perfect.
              </p>
              <p>You apply. You're excited.</p>
              <p>
                And then you wait. And wait. And wait some more. Did your application get lost? Did they already fill the position? Can you reach out to follow up?
              </p>
              <p>
                The answers: maybe, maybe, and yes. So, how exactly do you follow up?
              </p>
              <p>
                The short answer: Wait approximately 7–10 days, and if you're still hearing crickets, turn to the job posting and LinkedIn. Does the job posting have any contact information? If yes, you're golden. If not, check out the company's LinkedIn page and search for someone in HR or recruiting.
              </p>
              <p>
                The long answer:
                <a href="//www.topresume.com/career-advice/how-to-properly-follow-up-on-a-job-application" rel="noopener noreferrer" target="_blank">Learn more here</a>.
              </p>
            </section>
            <section id="section1b">
              <h3>Utilizing recruiters</h3>
              <p>
                Have you worked with recruiters in the past? Or if you haven't, are you interested in going that route this time around? While it's not for everyone, don't forget the “power of three” and explore every avenue.
              </p>
              <p>
                Working with an external recruiter can be a great resource for finding new job leads and gaining company insight. First step: Go back to your online presence and make sure it is as strong as possible so <a href={"//www.topresume.com/career-advice/how-to-get-headhunters-to-find-you" rel="noopener noreferrer" target="_blank">recruiters can find you</a>.
              </p>
              <p>
                Another way to make yourself discoverable by recruiters is to use a resume posting service, like <a href="//www.resumerabbit.com/" rel="noopener noreferrer" target="_blank">ResumeRabbit</a>. ResumeRabbit allows you to quickly and easily share your resume with 50 of the most popular job sites used by recruiters and hiring managers.
              </p>
              <p>
                You’ll get maximum exposure on your new and impressive resume with minimal effort.
              </p>
            </section>
            <section id="section1c">
              <h3>Networking</h3>
              <p>
                You knew we'd get to this point, and we know you didn't want us to unless you're a social butterfly. Networking is the part of “search” that could very well (and most likely will) be the way you find your next position.
              </p>
              <p>
                Networking means nurturing existing relationships and leveraging your personal and professional connections. Your next job could come from your roommate's sister's co-worker — you never know.
              </p>
              <p>
                Did you know you're 10 times more likely to land a job with an employee referral? Tap into that network! Reconnect,<a href="//www.topresume.com/career-advice/meaningful-linkedIn-network-connections" rel="noopener noreferrer" target="_blank"> reach out on LinkedIn</a>, and tell everyone you know you're on the hunt (except your current employer).
              </p>
              <p>
                If you're feeling like your network is lacking, check out online groups, meetup groups, conferences — they're all great ways to expand your network.
              </p>
            </section>
          </section>
          <UpsellBanner />
          <section id="section2">
            <h2>Seal the deal</h2>
            <p>
              You have prepped, you have searched, and now you have an interview! Get this next section just right, and you are going to close.
            </p>
            <section id="section2a">
              <h3>Interviews and follow-up</h3>
              <p>
                Your hard work has gotten you here, and you're ready for your interview.
              </p>
              <p>
                Here's a quick checklist of interview to-dos and objectives:
              </p>
              <ul>
                <li>
                  <strong>Dress for success.</strong> Look at the company's website and social media presence to get a sense of how employees dress.
                </li>
                <li>
                  <strong>Have your <a href="//www.topresume.com/career-advice/ask-amanda-how-to-answer-tell-me-about-yourself-in-a-job-interview" rel="noopener noreferrer" target="_blank">elevator pitch</a> prepped and ready to go.</strong> Just don't over-rehearse, and definitely don't just regurgitate your {{product}}. Keep it short and sweet, and give the interviewer a glimpse of why you're the right candidate while highlighting your achievements and personality. You've got this.
                </li>
                <li>
                  <strong>Practice answering the questions that make you nervous.</strong>You know the ones. The mere thought of them makes your skin crawl. We hate to be the bearer of bad news, but you'll definitely get asked them. Prepare, prepare some more, and then practice in front of the mirror. <span>Here are <a href="//www.topresume.com/career-advice/5-tricky-interview-questions-and-how-to-handle-them" rel="noopener noreferrer" target="_blank">five tricky questions</a> you might also want to nail down answers to.</span> If you're still feeling like your delivery could use some ironing out, enlist the help of professional interview coaches at
                  <a href="//www.topinterview.com/" rel="noopener noreferrer" target="_blank">TopInterview</a>. Your coach will create a personalized blueprint for interview success, targeting specific questions and interview styles relevant to the position you’re interviewing for. You only get one chance to impress in an interview, and interview coaching can help ensure you bring your best self forward.
                </li>
                <li><strong>Prepare a list of questions to ask your interviewers.</strong> On the topic of questions to ask, when they ask you, “<a href="//www.topresume.com/career-advice/13-of-the-smartest-questions-to-ask-a-hiring-manager" rel="noopener noreferrer" target="_blank">Do you have any questions for me?</a>” you'll want to be prepared to give an enthusiastic “Yes!” Have a few of these jotted down in a notebook ahead of time, and you'll look extra prepared, engaged, and genuinely interested in the role. Asking questions also will help you decide if the job and company are right for you.
                </li>
              </ul>
              <p>
                Think you're done once you exit the interview room? Think again.
              </p>
              <p>
                Next step: <a href="//www.topinterview.com/interview-advice/when-to-follow-up-after-your-interview" rel="noopener noreferrer" target="_blank">follow up</a>. And do so within 24 hours. It doesn't need to be a flashy note— just be sure to thank each interviewer for their time, personalize the message, reiterate your sincere interest, and end it with a question.
              </p>
              <p>
                Something along the lines of, “What do the next steps in this process look like?” will suffice.
              </p>
            </section>
            <section id="section2b">
              <h3>Negotiation</h3>
              <p>
                One important last step in the process is negotiation. Like any skill, you can learn to be a better negotiator over time — it just takes <a href="//www.topresume.com/career-advice/7-powerful-tips-navigating-through-tough-negotiators" rel="noopener noreferrer" target="_blank">a little research and a lot of practice</a>. Start with a little online research to make sure the salaryyou're asking for is backed up by data.
              </p>
              <p>
                Next, be clear on your minimum requirements and have a strategy for dealing with counter-offers. Remember, negotiation shouldn't be a win-lose situation; your goal is to settle on a number that all parties are happy with.
              </p>
              <p>
                In addition, a compensation package isn't just about pay. You can negotiate any number of items, from your job title to your number of vacation days, and much more.
              </p>
            </section>
          </section>
          <section>
            <h2>We’re here to help you make the job search a success</h2>
            <p>
              At {{brand}}, we are committed to your job search success. Everything we do is for you: we want to shorten your job search, tell your career story in a powerful and compelling way, help you impress an interviewer, secure a higher salary.
            </p>
            <p>
              If you need any support, feel free to chat with us and we’ll do our best to help.
            </p>
          </section>`,
          title: 'Taking the next step',
        },
        Zipjob: {
          date: 'Updated Oct 8, 2020',
          imgAlt: 'Next step icon',
          text: `<section>
            <p>Whether you’re hunting for your first job or your tenth, it’s vital to know how to find jobs in the most efficient manner possible. The good news is that modern communications technology provides more job-hunting options than previous generations could have ever imagined possible. The bad news is that some are far more effective than others.</p>
            <p>Searching for your next role can be challenging, especially if you don’t know where to start. If it’s been a while since you’ve been in the market for a new role, you may not know the best place to look for jobs. That’s where we come into the picture. In the following guide, we will reveal how to look for jobs in 2023, the best websites to look for jobs, plus advice on how you can get started.</p>
            <p>Here’s everything you need to know before you begin.</p>
            <h2>Where to start your job search</h2>
            <p>Ready to look for jobs? If you want to start the next chapter in your work life, it’s important to know where to start. You’re going to want to take a multi-pronged approach here. Don’t rely on just one tactic to land your next dream job. Instead, try some of the following ideas.</p>
            <h3>1. Start by checking online job boards</h3>
            <p>If you’re like many of today’s job seekers, you might think that the Internet is the best way to get a new job. Many job seekers spend hours each day searching the most popular online job boards and posting their resumes everywhere they can. Pew research reveals that <a rel="noopener noreferrer" target="_blank" href="https://www.pewresearch.org/internet/2015/11/19/1-the-internet-and-job-seeking/#:~:text=Narrowing the focus to the,applied to a job online.">90% of candidates use the Internet</a> when they are starting to look for jobs.</p>
            <p>It’s a no-brainer. When you are hoping to land your next position, the Internet is the most obvious place to start. Here is the core benefit of this method:</p>
            <ul>
              <li>
                You get the chance to research what employers are posting new jobs
              </li>
              <li>
                You gain insight into what skills employers want for people in your industry
              </li>
              <li>
                You learn what kind of experience hiring managers want to see in candidates with your job title
              </li>
              <li>
                You can identify any gaps in your qualifications or education to remain a competitive applicant
              </li>
              <li>
                You get the chance to research demand for people with your skill set
              </li>
            </ul>
            <p>When you’re looking for jobs online, be sure to cast your net wide. All too many job-seekers fall into the trap of using the same sites. That is a mistake. Keep in mind that variety is the spice of life. The more job sites you include in your search, the better.</p>
            <p>On top of using the Internet to find jobs, be sure to bolster your online search with other methods.</p>
            <h3>2. Use LinkedIn to network</h3>
            <p>LinkedIn is one of the best ways to network and land a job today. <a rel="noopener noreferrer" target="_blank" href="https://financesonline.com/linkedin-statistics/">72% of recruiters</a> use the social platform when they are hiring candidates for job openings. For that reason, you need to make sure that you use this particular tool when you want to look for jobs. LinkedIn is the largest congregation of people talking about jobs and opportunities.</p>
            <p>Don’t panic if you haven’t used the social networking site before now. There’s no time like the present. Setting up a LinkedIn profile and making your presence known is faster than you think. Ready to get started? If so, here are some of the core tips that you can use:</p>
            <ul>
              <li>
                <strong>Use a professional image. </strong>A picture is worth a thousand words. When a hiring manager looks at your LinkedIn profile, they will check out your picture too. You need to make sure that this image suits your professional persona.
              </li>
              <li>
                <strong>Make some connections. </strong>As you may already know, LinkedIn is all about connections. The stronger your professional network is, the more likely you are to uncover job opportunities. Try to build your network with at least 250+ connections.
              </li>
              <li>
                <strong>Get some recommendations. </strong>Other professionals can recommend you on LinkedIn. To show that you have a proven track record of success, ask some of your ex-coworkers or managers for recommendations. These testimonials will show up on your social profile, allowing recruiters to see the value that you bring. <br>
              </li>
            </ul>
            <p>Once you have set up, make sure you stay active on LinkedIn. Keep your profile updated with work experience and skills. Reach out to colleagues and friends who may be able to refer you to open positions. Remember that the grass is greener where you water it.</p>
            <h3>3. Contact hiring managers directly</h3>
            <p>Are you feeling bold? When we talk about how to look for jobs in 2023, here’s an approach that you may not have considered. Reaching out directly to a hiring manager at a company you want to work for is a good strategy for two reasons:</p>
            <ol>
              <li>
                Companies spend a ton of money on recruiting fees which they could save if they hire you directly. This could give you a leg up on other candidates.<br>
              </li>
              <li>
                It shows the hiring manager that you have a genuine interest in working for the company, which is always a plus.
              </li>
            </ol>
            <p>That’s a win-win scenario for both you and the hiring manager. Take the time to research key professionals in your sector. You may want to connect with them on LinkedIn or simply find their contact details.</p>
            <p>Sending them a well-crafted opening email may help you land a job. There’s an art when it comes to <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/job-search-cold-email/">cold emailing</a>, so you should do some research first. Tailoring each of your emails to meet the requirements of the business is the key.</p>
            <h3>4. Respond to job ads</h3>
            <p>You already know that job ads can be a great way to find out who’s hiring in your area or elsewhere in the country. Don’t always look in the same place. Employers may advertise new jobs online, in shop windows, or in print publications. For example, you may find that hiring managers place adverts in an industry magazine. Expand your search, if possible.</p>
            <p>The good news is that this can be a great way to find low to moderate-income positions. If you’re at mid-level in your career, you may find that this option works for you. While you don’t want to spend all of your time trawling through advertisements, set aside a time slot each day to build a routine for this rather than allowing it to take over.</p>
            <p>Make sure you research companies before you apply to them! When you’re dealing with job adverts, you don’t always know whether they are legitimate. To protect yourself, take a moment to Google the company and look up the key decision-makers on LinkedIn. You could even dig deeper and look at any news articles or media surrounding the business.</p>
            <h3>5. Seek out government offices for help</h3>
            <p>Here’s an approach that many job seekers overlook. Government job placement offices are another option when you’re trying to find a job. There are government employment offices that can provide you with assistance. Many offer training programs to teach you how to find jobs, and staff can often provide you with hot leads on job openings. If you’re struggling to come up with the leads you need, this is an option that may work for you.</p>
            <p>Much of the time, fresh candidates find it hard as they don’t have the complete skills that jobs look for. Looking into government schemes is a quick way to upskill yourself and open the door to more opportunities. Whether you want to move into a new sector or simply climb the career ladder, this is a savvy move to make. Why not start searching now?</p>
            <h3>6. Ask for professional referrals</h3>
            <p>Your professional network is your biggest strength. By now, you should have some semblance of a community in place. You can use that network of friends, family members, former co-workers, educators, and LinkedIn contacts to generate job leads.</p>
            <p>If you’re not using your network, you could be missing a trick because almost <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/requesting-job-referrals/">40% of referred candidates land the job</a>. You just need to maintain contact with your network and continue to ask about potential job openings. While this can feel weird to start with, it’s a simple way to learn about existing vacancies. The old saying rings true —it’s not what you know, it’s who you know. This approach will help you skip the queue for new roles.</p>
            <h2>The best places to look for jobs in 2023</h2>
            <p>In addition to knowing how to look for jobs, it can be helpful to know where to find jobs in 2023. As we have already covered, it’s important to cast your net as wide as you can. To help you out, we’ve compiled a brief list of the top information sources you should be using to identify available jobs in your area:</p>
            <h3>1. Classified ads</h3>
            <p>Classified ads can still be a great way to find out about job openings. That includes ads in your local paper, of course, but you’ll likely find even more opportunities online on sites like <a rel="noopener noreferrer" target="_blank" href="http://www.moneycrashers.com/find-job-online-craigslist/">Craigslist</a>. In fact, many small companies rely on affordable, online job postings to locate new hires these days, rather than the traditional newspaper “help wanted” ads of yesteryear. Take the time to look through these boards and see what you can find.</p>
            <p>Believe it or not, many of the best opportunities will be in a local publication of some kind. Look for job search resources in your city — you might be surprised at the jobs that aren’t posted anywhere else! Set aside some time to read through a local magazine or paper.</p>
            <h3>2. Job boards</h3>
            <p>Next up, you need to know what the best sites to look for jobs are. There are several online job boards that you can try. However, these boards are not created equal. Your success in finding a potential opening will depend on your profession and where you look. Here are some examples of industry-specific job search sites we’ve researched for you:</p>
            <ul>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/best-executive-job-search-sites/">Job Search Sites For Executives</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/best-nursing-job-search-sites/">Job Search Sites For Nurses</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/job-search-sites-for-remote-work/">Job Search Sites For Remote Work</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/10-best-product-manager-job-search-sites/">Job Search Sites For Product Managers</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/best-healthcare-professional-job-search-sites/">Job Search Sites For Healthcare</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/10-best-legal-professional-job-search-sites/">Job Search Sites For Lawyers</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/15-best-engineer-job-search-sites/">Job Search Sites For Engineers</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/7-best-government-job-search-sites/">Job Search Sites For Government Jobs</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/10-best-software-developer-job-search-sites/">Job Search Sites For Software Developers
                </p>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/best-accountant-job-search-sites/">Job Search Sites For Accountants</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/10-best-college-grad-job-search-sites/">Job Search Sites For College Grads</a>
              </li>
            </ul>
            <p>Rather than using a generic job site, it pays to narrow down your search. For example, you may want to look into the best place to look for remote jobs or the best boards for entry-level positions. Consider what makes your job search unique and start from there.</p>
            <h3>3. Google</h3>
            <p>Is there anything Google doesn’t do these days? Yes, you can use Google to find job openings in your area. You can also use it to research those firms and learn more about them to help you tailor your resume to meet their needs. So, when you are starting to look for jobs, this is likely to be one of the tools that you utilize the most.</p>
            <p>Start by Googling positions in your field and area. That will bring up job advertisements and boards that you may want to use in your search. When you have some “hits,” you can also use the search engine to find out more about the role. You may want to look at company reviews on Glassdoor, for example, or find out more about the business values.</p>
            <h3>4. Social media</h3>
            <p>Don’t overlook the power of social media when you look for jobs. Whether it’s LinkedIn, Facebook, or Twitter, today’s social media can be a great way to learn about job openings. We’ve already talked about how your LinkedIn network can provide you with plenty of insight into companies that might be hiring. The site also has groups, advanced search filters, and job postings, all of which you can use to your advantage.</p>
            <p>Meanwhile, you can often locate choice job postings on those other social media sites as well. There are many Facebook groups based on location or industry which you should check out. Applying to join some of these groups may allow you to see openings that you otherwise would not know about. Business leaders can post vacancies in the groups.</p>
            <p>It doesn’t end there. Twitter is also a great place to connect with people in your industry or employees at your target companies. You might want to follow certain professionals, search for jobs using the hashtag feature, or even post that you are looking for a new role. (Of course, if you decide to do the latter, consider that anyone can see your tweet!)</p>
            <h3>5. Company websites</h3>
            <p>Keep tabs on the job pages for a select group of your ideal employers. If you’ve ever searched through a company’s website, chances are that you noticed that many post open positions online. Many companies have started to rely on these postings as they provide a way to help screen potential applicants. If there’s a company that you really want to join, the website can be an ideal place to start that process.</p>
            <h3>6. Job aggregators</h3>
            <p>Indeed and other job aggregators collect job postings from a variety of different sites on the Internet. You can use these aggregators to learn about jobs in your area and follow posting links to start the application process.</p>
            <p>You should know that this is what millions of other people are doing as well. To really stand out, you need to support your job aggregator efforts with networking, <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/job-search-cold-email/">cold outreach</a>, and making connections with people.</p>
            <h3>7. Your professional network</h3>
            <p>You can use your professional network to keep abreast of job openings in your industry. However, you’ll be missing out if you don’t also use it to help you obtain a job. You can dramatically increase your chances of getting hired if you can tell a potential employer that you were referred by one of their employees. Those chances get even better if that employee has recommended you for the job. Don’t miss out on this crucial tip!</p>
            <h2>The takeaway</h2>
            <p>Sure, the job market is <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/why-is-it-so-hard-to-find-a-job/">more competitive than ever before</a>, but you can still find a great job if you know what you’re doing. By learning how to look for a job and identifying the best places to find the job you need, you’ll dramatically improve your job-seeking success. Until then, keep using these techniques to look for jobs and find out about new opportunities.</p>
            <p><strong>Looking for your next job? ZipJob’s team of professional resume writers are here to help! Look at our expert-backed </strong><a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/services/"><strong>resume writing services</strong></a><strong> to get started. Investing in your job search is a smart way to land your next position.</strong></p>
            <p><strong>If you already have a resume in place, start with our free resume review tool to </strong><a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/ats-resume-test/"><strong>see how your resume looks to an expert and an ATS scan</strong></a><strong>.</strong></p>
            <h2>Recommended reading:</h2>
            <ul>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/networking-for-career-development/">5 Reasons Why Networking is Vital for Career Development</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/ats-resume-test/">ATS Resume Test: Free ATS Checker & Formatting Examples (2023)</a>
              </li>
              <li>
                <a rel="noopener noreferrer" target="_blank" href="https://www.zipjob.com/blog/free-certifications-for-resume/">7 Free Certifications To Enhance Your Resume</a>
              </li>
            </ul>
          </section>`,
          title: 'Taking the next step',
        },
      },
      PageShell: {
        titleTemplate: '%s | {{brandName}} Customer Portal',
        titleTemplateNoBrand: '%s | Customer Portal',
      },
      Account: {
        address: 'Address',
        cancel: 'Cancel',
        current_password: 'Current password',
        new_password: 'New password',
        new_password_verification: 'Confirm password',
        edit: 'Edit',
        email: 'Email',
        first_name: 'First name',
        last_name: 'Last name',
        loginTitle: 'Login and Security',
        purchaseHistoryTitle: 'Purchase History',
        purchaseHistoryLink: 'View your purchase history',
        name: 'Name',
        notCurrentPassword: 'Password does not match records',
        pageTitle: 'Account',
        password: 'Password',
        passwordMatch:
          'The password you entered does not match the password on record',
        passwordsDontMatch: 'Passwords do not match',
        passwordUpdatedLast: 'Last updated {{date}} ago',
        personalInfo: 'Personal Information',
        phone: 'Phone Number',
        required: 'Required',
        save: 'Save',
        timezone: 'Timezone',
        language: 'Language',
        validation: {
          [UserValidationMessageKeys.InvalidEmail]: 'Invalid email',
          [UserValidationMessageKeys.PasswordsDoNotMatch]:
            'Passwords do not match',
          [UserValidationMessageKeys.WrongCurrentPassword]:
            'Current password is incorrect',
          [UserValidationMessageKeys.ConflictingEmailAddress]:
            "We're sorry, another user has this email address",
          [UserValidationMessageKeys.CouldNotUpdateEmail]:
            'We could not update your email address. Please contact support.',
        },
        LanguageInput: {
          languages: {
            'en-US': 'English (US)',
            'en-GB': 'English (GB)',
          },
        },
      },
      Auth: {
        Login: {
          pageTitle: 'Login',
        },
        SignUp: {
          pageTitle: 'Sign Up',
        },
      },
      Documents: {
        completed: 'Completed on {{date}} by {{expert}}',
        download: 'Download',
        draft: 'Draft {{number}}',
        draftExpected: 'Your draft is expected by {{date}}',
        draftFrom: 'Draft from {{completed}}',
        draftImageAlt: 'Draft in progress',
        newDraft: 'Your new draft is expected in 1-2 days',
        pageTitle: 'Documents',
        requestEdits: 'Request edits or approve within the revision period',
        rate: 'Rate your finished {{product}} from {{expert}}',
        draftDateFormat: 'EEE, MMM d, yyyy, h:mm aaa',
        responseDateFormat: 'M/d/yy',
        emptyState: {
          [ItemStatusTypes.FirstDraft]: {
            header: '{{expert}} is working on your {{product}}',
            copy: 'Your draft is expected by {{date}}',
          },
          [ItemStatusTypes.AwaitingWriterMatch]: {
            header:
              'Sit tight while we find you the perfect expert to work on your {{product}}',
            copy: 'This usually takes a day or two',
          },
          [ItemStatusTypes.AwaitingResume]: {
            header: '{{expert}} is working on your {{product}}',
            copy: 'Your draft will be delivered after you approve your {{brandProduct}}',
          },
          dateFormat: 'MMM d',
        },
        extraButton: {
          approve: 'Approve Order',
          approveDisabled:
            'You may approve your order once all documents have been received',
          print: 'Print',
          [ItemStatusTypes.Complete]: 'Leave a rating',
        },
        cta: {
          viewDocuments: 'View Documents',
          viewMessages: 'View messages and request edits from your writer',
        },
      },
      Home: {
        click: 'Click to view your order details',
        delivery: 'Item Delivery Dates',
        questionnaireDate: 'Questionnaire Date',
        endOfRevision: 'End of Revision Period',
        submitted: 'Submitted',
        pending: 'Pending',
        gotIt: 'Got it',
        greeting: 'Welcome',
        greetingError:
          'There was an issue loading your account. Please try again later.',
        hurryCopy:
          '{{name}}, you have a set period of time to send edits!<br> The quicker you send feedback, the more revisions you can get',
        loading: 'Loading user data...',
        pageTitle: 'Home',
        yourPortfolio: {
          rateDocuments: 'Rate your documents',
        },
      },
      Portfolio: {
        approve: 'Approve Order',
        download: 'Download',
        pageTitle: 'Portfolio',
        postApproval: 'Completed on: {{date}}',
        postDraft: 'Revision period: {{date}}',
        preDraft: 'Draft expected: {{date}}',
        purchasedOn: 'Purchased on {{date}}',
        purchasedOnFormat: 'LLLL d, yyyy',
        view: 'View',
        nameAnd: 'and',
        nameWith: 'with',
      },
      Upgrade: {
        pageTitle: 'Upgrade',
        add: 'Add',
        remove: 'Remove',
        confirm: 'Confirm',
        great_value: 'Great Value',
        new_product: 'New Product',
        header: {
          heading: 'Explore our other services',
          desc_part_1_purchase: `{{name}}, we recommend the following services to take your career to the next level.`,
        },
        groups: {
          last_chance: {
            heading: 'Last Chance Items',
            description: 'Get ahead of the crowd with help from our experts!',
          },
          other: {
            heading: 'Other Services',
            description: '',
          },
        },
        last_chance_heading: 'Last Chance Items',
        last_chance_description:
          'Act fast, these services won’t be available once you’re matched with an expert.',
      },
      Error: {
        title: 'Oops! We have a problem',
        copy1:
          'We’re currently working through a {{code}} error and can’t find this page.',
        copy2:
          'You can go back to <back>where you were</back> or straight to the <home>home page</home>.',
      },
      PurchaseHistory: {
        pageTitle: 'Purchase History',
        listBrand: 'Brand',
        listDate: 'Date',
        listItem: 'Item',
        listPaymentMethod: 'Payment Method',
        listTotal: 'Total',
        listStatus: 'Status',
        dateFormat: 'MM/dd/yy',
        transactionStatus: {
          'refund-full': 'Refund',
          'refund-partial': 'Partial Refund',
          chargeback: 'Chargeback',
          processed: 'Processed',
        },
        giftCard: 'Giftcard',
        applePay: 'Apple Pay',
      },
    },
    itemTypes: {
      [ItemTypes.AcceptanceLetter]: 'Acceptance Letter',
      [ItemTypes.CareerBiography]: 'Career Biogrophy',
      [ItemTypes.CoverLetter]: 'Cover Letter',
      [ItemTypes.CurriculumVitae]: 'Curriculum Vitae',
      [ItemTypes.Guarantee]: 'Guarantee',
      [ItemTypes.KSADocument]: 'KSA Document',
      [ItemTypes.AdditionalDocumentKSA]: 'KSA Document',
      [ItemTypes.LinkedInDocument]: 'LinkedIn Makeover',
      [ItemTypes.OfferDeclinedLetter]: 'Offer Declined Letter',
      [ItemTypes.PersonalConsultation]: 'Personal Consultation',
      [ItemTypes.PhoneCall]: 'Phone Call',
      [ItemTypes.PersonalConsultationPhoneCall]: 'Phone Call',
      [ItemTypes.PersonalConsultationPhoneCallPreDocument]: 'Phone Call',
      [ItemTypes.PersonalConsultationPhoneCallPrePostDocument]: 'Phone Call',
      [ItemTypes.PostingService]: 'Posting Service',
      [ItemTypes.RecruiterService]: 'Recruiter Service',
      [ItemTypes.ReferencesPage]: 'References Page',
      [ItemTypes.ResignationLetter]: 'Resignation Letter',
      [ItemTypes.Resume]: 'Resume',
      [ItemTypes.RushOrder]: 'Rush Service',
      [ItemTypes.ThankYouLetter]: 'Thank You Letter',
      [ItemTypes.AdditionalDocumentThankYouLetter]: 'Thank You Letter',
      [ItemTypes.AdditionalDocumentFollowUpLetter]: 'Follow Up Letter',
      [ItemTypes.AdditionalDocumentReferencesPage]: 'References Page',
      [ItemTypes.AdditionalDocumentBiography]: 'Biography',
      [ItemTypes.AdditionalDocumentAcceptanceLetter]: 'Acceptance Letter',
      [ItemTypes.AdditionalDocumentDeclineLetter]: 'Decline Letter',
      [ItemTypes.AdditionalDocumentResignationLetter]: 'Resignation Letter',
      [ItemTypes.InterviewPrep]: 'Interview Prep',
    },
    packages: {
      careerEvolution: 'Career Evolution Package',
      coverLetter: 'Cover Letter Package',
      executivePriority: 'Executive Priority Package',
      linkedin: 'LinkedIn Package',
      professionalGrowth: 'Professional Growth Package',
      thankYouLetter: 'Thank You Letter Package',
    },
    placeholder: 'This is some placeholder text',
    products: {
      coverLetter: 'Cover Letter',
      linkedin: 'LinkedIn',
      resume: 'Resume',
      thankYouLetter: 'Thank You Letter',
    },
    routes: {
      account: '/account',
      article1: '/what-to-expect',
      article2: '/getting-to-know-our-writers',
      article3: '/setting-the-stage-for-a-successful-search',
      article4: '/collaborating-with-your-writer',
      article5: '/taking-the-next-step',
      faqs: '/faqs',
      home: '/',
      login: '/login',
      logout: '/logout',
      order: '/order',
      purchaseHistory: '/history',
      portfolio: '/portfolio',
      resetPassword: '/reset-password',
      upgrade: '/upgrade',
      bundledUpgradesByEmail: '/upgrade/:bundle/:email',
      bundledUpgrades: '/upgrade/:bundle',
      productUpgrades: '/upgrade/products/:planCode',
      messages: '/messages',
      messagesByClient: '/messages/:clientID',
      completedMessagesByClient: '/messages/:clientID/:status(completed)',
      messagesByClientAndItem: '/messages/:clientID/document/:itemID',
      call: '/call/:omsItemID',
      schedule: '/schedule/:omsItemID',
    },
    status: {
      approval: 'Approval needed',
      draft: 'Draft in progress',
      feedback: 'Ready for feedback',
      [ItemStatusTypes.RevisionMode]: '{{daysLeft}} day left to request edits',
      [`${ItemStatusTypes.RevisionMode}_plural`]:
        '{{daysLeft}} days left to request edits',
      writerMatch: 'Writer match in progress',
      [ItemStatusTypes.AwaitingWriterMatch]: 'Writer match in progress',
      [ItemStatusTypes.AwaitingScheduling]: 'Schedule Interview Now',
      [ItemStatusTypes.ConductCall]: 'Call scheduled',
      [ItemStatusTypes.FirstDraft]: 'Draft in progress',
      [ItemStatusTypes.AwaitingResume]: 'Draft in progress',
      [ItemStatusTypes.AwaitingReply]: 'Ready for feedback',
      [ItemStatusTypes.ScheduleInterview]: 'Schedule interview',
      [ItemStatusTypes.ConductInterview]: 'Interview scheduled',
      [ItemStatusTypes.AwaitingPhoneCall]: 'Expert match in progress',
      [ItemStatusTypes.SchedulePhone]: 'Schedule phone call',
      [ItemStatusTypes.SendMessage]: 'Ready for feedback',
      [ItemStatusTypes.SendLinkedin]: 'LinkedIn in progress',
      [ItemStatusTypes.SendInterviewSummary]: 'Summary in progress',
      [ItemStatusTypes.Complete]: 'Delivered',
    },
    utils: {
      formatting: {
        and: 'and',
      },
    },
    shared: {
      order: 'Order',
      yourExpert: 'your Expert',
      YourExpert: 'Your Expert',
      yourWriter: 'your Writer',
      document: 'document',
    },
  },
}
