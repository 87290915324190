import React, { AnchorHTMLAttributes } from 'react'
import {
  LinkProps,
  NavLink,
  NavLinkProps,
  Redirect,
  RedirectProps,
} from 'react-router-dom'
import Button, { ButtonProps } from '@material-ui/core/Button'

import { getGhostIdentifier, getGhostSearchParams } from 'utils/ghosting'

export type GhostLinkProps =
  | NavLinkProps
  | AnchorHTMLAttributes<HTMLAnchorElement>

const GhostLink: React.FC<GhostLinkProps> = (props) => {
  if (props.href) {
    return (
      <a
        href={props.href}
        {...(props as AnchorHTMLAttributes<HTMLAnchorElement>)}
      >
        {props.children}
      </a>
    )
  }

  if (props.onClick) {
    return <Button {...(props as any[])}>{props.children}</Button>
  }

  let to = (props as NavLinkProps).to
  switch (typeof to) {
    case 'string':
      const urlObject = new URL(to, window.location.origin)
      const searchParams = urlObject.searchParams
      Object.entries(getGhostSearchParams()).forEach(([key, value]) =>
        searchParams.set(key, value)
      )
      to = {
        pathname: to,
        search: `?${searchParams.toString()}`,
      }
      break
  }

  return typeof to !== 'undefined' ? (
    <NavLink {...props} to={to}>
      {props.children}
    </NavLink>
  ) : null
}

export interface GhostLinkButtonProps
  extends Omit<ButtonProps, 'onClick' | 'component'> {
  to: LinkProps['to']
}

export const GhostLinkButton: React.FC<GhostLinkButtonProps> = ({
  to,
  ...props
}) => (
  <Button
    {...props}
    // @ts-ignore
    to={to}
    // @ts-ignore
    component={(props: any[]) => <GhostLink {...props} />}
  />
)

export const generateGhostURL = (url: string): string => {
  const ghostID = getGhostIdentifier()

  if (!ghostID) {
    return url
  }

  let safeURL = url

  if (!safeURL.includes('http')) {
    safeURL = window.location.origin + safeURL
  }

  const parsed = new URL(safeURL)
  const params = new URLSearchParams(parsed.search)
  params.set('ghost', ghostID)
  parsed.search = params.toString()

  return parsed.toString()
}

export type GhostRedirectProps = RedirectProps

export const GhostRedirect: React.FC<GhostRedirectProps> = (props) => {
  const ghostID = getGhostIdentifier()
  let to = props.to
  let shouldApplyGhost = true

  switch (typeof to) {
    case 'string':
      to = {
        pathname: to,
      }
      break
    case 'function':
      shouldApplyGhost = false
      break
  }

  if (ghostID && shouldApplyGhost && typeof to !== 'function') {
    const searchParams = new URLSearchParams(to.search?.toString() || '')
    Object.entries(getGhostSearchParams()).forEach(([key, value]) =>
      searchParams.set(key, value)
    )
    to.search = `?${searchParams.toString()}`
  }

  return <Redirect {...props} to={to} />
}

export default GhostLink
