import { createTheme } from '@material-ui/core/styles'
import createBreakpoints from '@material-ui/core/styles/createBreakpoints'
import pnWoff from 'assets/fonts/pntopresume.woff'
import pnWoff2 from 'assets/fonts/pntopresume.woff2'
import pnMWoff from 'assets/fonts/pntopresume-m.woff'
import pnMWoff2 from 'assets/fonts/pntopresume-m.woff2'
import pnSBWoff from 'assets/fonts/pntopresume-sb.woff'
import pnSBWoff2 from 'assets/fonts/pntopresume-sb.woff2'
import pnBWoff from 'assets/fonts/pntopresume-b.woff'
import pnBWoff2 from 'assets/fonts/pntopresume-b.woff2'
import tgaBWoff from 'assets/fonts/texgyreadventor-b.woff'
import tgaBWoff2 from 'assets/fonts/texgyreadventor-b.woff2'

const breakpoints = createBreakpoints({})

// Shades are based off of font weight numbers
// 400 = normal
// 300 = light
// 700 = dark
export const colors = {
  blue: {
    100: '#eef6fc', // Section BGs
    200: '#d4e9f8', // Stepper Inactive Section
    300: '#86d0f5', // Inactive Buttons
    400: '#0da1ec', // Links, Buttons, Checkmarks, Dropdown Arrows, Gallery Arrows
    500: '#1d86e8', // Stepper Active
    600: '#333954', // Lost Connection BG
    700: '#052a4d', // Main Text
    a100: 'rgba(13, 161, 236, 0.15)', // Article highlight
    a200: 'rgba(13, 161, 236, 0.35)', // Article highlight hover
    a300: '#a0c2e5', // Modal line item icons
    b300: '#b8cde2', // Upsell image bg
    a400: '#0e83eb', // Button hover
    a500: '#deecf9', // Light blue in footer, Content pages header BG
    l100: '#18a2c4', //Linkedin header color
    r100: '#051f55', //Resume Refresh background color
    r200: '#7f9ad1', //Resume Refresh purple/blue
    p100: '#e6f5fd', //success progress widget/somewhat prepared
    u100: '#ecf0f6', // upgrade nav border
  },
  gray: {
    100: '#f5f8fb', // App BG, outlined button hover bg
    200: '#f3f3f4', // Secondary Buttons
    300: '#dfdfdf', // Icons, Paper border
    400: '#66677d', // Secondary Text
    500: '#e1e4e7', // Form Inputs
    600: '#e4e8f0', // Notification borders, outlined button border
    a100: '#edf0f5', // Modal line item icons bg
    a200: '#f9f9f9', // Disabled secondary buttons
    b200: '#e8e8e9', // Secondary button hover
    c200: '#eceff5', // inactive tab bg
    a300: '#a9afbc', // inactive tab badge dot
    a400: '#052a4d', // Your portfolio text
    a500: '#adadba', // Disabled order progress step
    b400: '#b2b3be', // Disabled contained button text
    a600: '#f1f3f7', // Disabled outlined button border
    a700: '#505050', // Document preview text
    b600: '#e4e8ef', // Light gray in progress dots
    p100: '#01254d', // success progress widget/ incomplete step
  },
  green: {
    300: '#d0f2ee', // Green Chip BG
    400: '#0fbb9f', // Green Chip Text, Connected Text, Correct Text
    500: '#2dcaa8', // Success Progress Widget / fully prepared
    600: '#00706e', // Product Pages / Discount percent
  },
  orange: {
    200: 'rgba(255,208,197,0.3)', // Orange Chip BG
    300: '#fabba4', // Rating Stars
    400: '#ff896f', // Orange Chip Text
    500: '#ff896b', //upsell expiry date
    600: '#fff0ed', //new upgrade nav menu element
  },
  red: {
    200: '#ffe1e1', // Red Chip Background
    400: '#ff6b6b', // Errors, Red Chip Text
  },
  black: {
    100: '#232323', // Input text
  },
  yellow: {
    100: '#ffcc00', // Warning yellow
    200: '#eba10e', // Success Progress Widget / somewhat prepared
  },
  white: {
    100: '#ffffff',
  },
}

const proximaNova = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: 400,
  src: `
    url(${pnWoff2}) format('woff2'),
    url(${pnWoff}) format('woff')
  `,
}
const proximaNovaMedium = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 500,
  src: `
    url(${pnMWoff2}) format('woff2'),
    url(${pnMWoff}) format('woff')
  `,
}
const proximaNovaSemiBold = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 600,
  src: `
    url(${pnSBWoff2}) format('woff2'),
    url(${pnSBWoff}) format('woff')
  `,
}
const proximaNovaBold = {
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${pnBWoff2}) format('woff2'),
    url(${pnBWoff}) format('woff')
  `,
}
const texGyreAdventorBold = {
  fontFamily: 'Tex Gyre Adventor',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    url(${tgaBWoff2}) format('woff2'),
    url(${tgaBWoff}) format('woff')
  `,
}

export const MUITheme = createTheme({
  palette: {
    background: {
      default: colors.gray[100],
    },
    primary: {
      light: colors.blue[300],
      main: colors.blue[400],
      dark: colors.blue[700],
    },
    secondary: {
      light: colors.gray[300],
      main: colors.gray[400],
    },
    error: {
      light: colors.red[200],
      main: colors.red[400],
    },
    warning: {
      light: colors.orange[200],
      main: colors.orange[400],
    },
    success: {
      light: colors.green[300],
      main: colors.green[400],
    },
    info: {
      main: colors.gray[300],
    },
    text: {
      primary: colors.blue[700],
      secondary: colors.gray[400],
    },
    divider: colors.gray[600],
  },
  typography: {
    fontFamily: 'Proxima Nova, Arial, sans-serif',
    fontSize: 16,
    htmlFontSize: 16,
    h1: {
      color: colors.blue[700],
      fontFamily: 'Tex Gyre Adventor, Proxima Nova, Arial, sans-serif',
      fontSize: '2.13em',
      fontWeight: 700,
      lineHeight: '1.41em',
    },
    h2: {
      fontSize: '1.25em',
      fontWeight: 700,
      lineHeight: '1.4em',
    },
    h3: {
      fontSize: '1.13em',
      fontWeight: 500,
      lineHeight: '1.56em',
    },
    h4: {
      fontSize: '.88em',
      fontWeight: 500,
      lineHeight: '1.63em',
    },
    h5: {
      color: colors.gray[400],
      fontSize: '1em',
      fontWeight: 500,
      lineHeight: '1.63em',
    },
    body1: {
      color: colors.gray[400],
      fontSize: '1.13em',
      fontWeight: 400,
      lineHeight: '1.56em',
    },
    body2: {
      color: colors.gray[400],
      fontSize: '1em',
      lineHeight: '1.5em',
    },
    subtitle1: {
      fontFamily: 'Tex Gyre Adventor, Proxima Nova, Arial, sans-serif',
      fontSize: '1.5em',
      fontWeight: 700,
      lineHeight: '1.58em',
    },
    subtitle2: {
      color: colors.gray[400],
      fontSize: '.88em',
      fontWeight: 500,
      textTransform: 'uppercase',
    },
    button: {
      color: 'white',
      fontSize: '1.13em',
      fontWeight: 600,
      lineHeight: '1.6em',
      textAlign: 'center',
    },
  },
  shape: {
    borderRadius: 6,
  },
  shadows: [
    'none',
    '0px 2px 1px -1px rgba(186, 190, 204, 0.2), 0px 1px 1px 0px rgba(186, 190, 204, 0.14), 0px 1px 3px 0px rgba(186, 190, 204, 0.12)',
    '0px 3px 1px -2px rgba(186, 190, 204, 0.2), 0px 2px 2px 0px rgba(186, 190, 204, 0.14), 0px 1px 5px 0px rgba(186, 190, 204, 0.12)',
    '0px 3px 3px -2px rgba(186, 190, 204, 0.2), 0px 3px 4px 0px rgba(186, 190, 204, 0.14), 0px 1px 8px 0px rgba(186, 190, 204, 0.12)',
    '0px 2px 4px -1px rgba(186, 190, 204, 0.2), 0px 4px 5px 0px rgba(186, 190, 204, 0.14), 0px 1px 10px 0px rgba(186, 190, 204, 0.12)',
    '0px 3px 5px -1px rgba(186, 190, 204, 0.2), 0px 5px 8px 0px rgba(186, 190, 204, 0.14), 0px 1px 14px 0px rgba(186, 190, 204, 0.12)',
    '0px 3px 5px -1px rgba(186, 190, 204, 0.2), 0px 6px 10px 0px rgba(186, 190, 204, 0.14), 0px 1px 18px 0px rgba(186, 190, 204, 0.12)',
    '0px 4px 5px -2px rgba(186, 190, 204, 0.2), 0px 7px 10px 1px rgba(186, 190, 204, 0.14), 0px 2px 16px 1px rgba(186, 190, 204, 0.12)',
    '0px 5px 5px -3px rgba(186, 190, 204, 0.2), 0px 8px 10px 1px rgba(186, 190, 204, 0.14), 0px 3px 14px 2px rgba(186, 190, 204, 0.12)',
    '0px 5px 6px -3px rgba(186, 190, 204, 0.2), 0px 9px 12px 1px rgba(186, 190, 204, 0.14), 0px 3px 16px 2px rgba(186, 190, 204, 0.12)',
    '0px 6px 6px -3px rgba(186, 190, 204, 0.2), 0px 10px 14px 1px rgba(186, 190, 204, 0.14), 0px 4px 18px 3px rgba(186, 190, 204, 0.12)',
    '0px 6px 7px -4px rgba(186, 190, 204, 0.2), 0px 11px 15px 1px rgba(186, 190, 204, 0.14), 0px 4px 20px 3px rgba(186, 190, 204, 0.12)',
    '0px 7px 8px -4px rgba(186, 190, 204, 0.2), 0px 12px 17px 2px rgba(186, 190, 204, 0.14), 0px 5px 22px 4px rgba(186, 190, 204, 0.12)',
    '0px 7px 8px -4px rgba(186, 190, 204, 0.2), 0px 13px 19px 2px rgba(186, 190, 204, 0.14), 0px 5px 24px 4px rgba(186, 190, 204, 0.12)',
    '0px 7px 9px -4px rgba(186, 190, 204, 0.2), 0px 14px 21px 2px rgba(186, 190, 204, 0.14), 0px 5px 26px 4px rgba(186, 190, 204, 0.12)',
    '0px 8px 9px -5px rgba(186, 190, 204, 0.2), 0px 15px 22px 2px rgba(186, 190, 204, 0.14), 0px 6px 28px 5px rgba(186, 190, 204, 0.12)',
    '0px 8px 10px -5px rgba(186, 190, 204, 0.2), 0px 16px 24px 2px rgba(186, 190, 204, 0.14), 0px 6px 30px 5px rgba(186, 190, 204, 0.12)',
    '0px 8px 11px -5px rgba(186, 190, 204, 0.2), 0px 17px 26px 2px rgba(186, 190, 204, 0.14), 0px 6px 32px 5px rgba(186, 190, 204, 0.12)',
    '0px 9px 11px -5px rgba(186, 190, 204, 0.2), 0px 18px 28px 2px rgba(186, 190, 204, 0.14), 0px 7px 34px 6px rgba(186, 190, 204, 0.12)',
    '0px 9px 12px -6px rgba(186, 190, 204, 0.2), 0px 19px 29px 2px rgba(186, 190, 204, 0.14), 0px 7px 36px 6px rgba(186, 190, 204, 0.12)',
    '0px 10px 13px -6px rgba(186, 190, 204, 0.2), 0px 20px 31px 3px rgba(186, 190, 204, 0.14), 0px 8px 38px 7px rgba(186, 190, 204, 0.12)',
    '0px 10px 13px -6px rgba(186, 190, 204, 0.2), 0px 21px 33px 3px rgba(186, 190, 204, 0.14), 0px 8px 40px 7px rgba(186, 190, 204, 0.12)',
    '0px 10px 14px -6px rgba(186, 190, 204, 0.2), 0px 22px 35px 3px rgba(186, 190, 204, 0.14), 0px 8px 42px 7px rgba(186, 190, 204, 0.12)',
    '0px 11px 14px -7px rgba(186, 190, 204, 0.2), 0px 23px 36px 3px rgba(186, 190, 204, 0.14), 0px 9px 44px 8px rgba(186, 190, 204, 0.12)',
    '0px 11px 15px -7px rgba(186, 190, 204, 0.2), 0px 24px 38px 3px rgba(186, 190, 204, 0.14), 0px 9px 46px 8px rgba(186, 190, 204, 0.12)',
  ],
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          proximaNova,
          proximaNovaMedium,
          proximaNovaSemiBold,
          proximaNovaBold,
          texGyreAdventorBold,
        ],
      },
    },
    MuiAccordion: {
      root: {
        borderTop: 'none',
        borderBottom: '1px solid #e4e8f0',
        boxShadow: 'none',
      },
    },
    MuiAvatar: {
      colorDefault: {
        backgroundColor: '#d3e9f7',
        color: '#09133b',
        fontFamily: '"Tex Gyre Adventor", "Proxima Nova", "Arial", sans-serif',
        fontWeight: 'bold',
        fontSize: '1em',
      },
    },
    MuiBackdrop: {
      root: {
        // @TODO Move this somewhere
        backgroundColor: 'rgba(19, 19, 20, 0.8)',
      },
    },
    MuiButton: {
      root: {
        padding: '.56em 1.2em',
        textTransform: 'inherit',
      },
      contained: {
        backgroundColor: colors.gray[200],
        color: colors.gray[400],
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: colors.gray.b200,
          boxShadow: 'none',
        },
        '&$disabled': {
          backgroundColor: `${colors.gray.a200}`,
          color: `${colors.gray.b400}`,
        },
      },
      containedPrimary: {
        color: '#fff',
        '&:hover': {
          backgroundColor: colors.blue.a400,
        },
      },
      label: {
        lineHeight: '1.5',
      },
      outlined: {
        border: `1px solid ${colors.gray[600]}`,
        color: colors.blue[400],
        padding: '.56em 1.2em',
        '&:hover': {
          backgroundColor: colors.gray[100],
        },
        '&$disabled': {
          border: `1px solid ${colors.gray.a600}`,
          color: `${colors.blue[300]}`,
        },
      },
      text: {
        padding: '0',
      },
      textPrimary: {
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.blue.a400,
        },
      },
    },
    MuiCheckbox: {
      root: {
        color: colors.gray[200],
        height: '1.5em',
        marginRight: '.9em',
        padding: '0',
        width: '1.5em',

        '& svg': {
          height: '100%',
          width: '100%',
        },
        '&$checked': {
          color: colors.gray[400],
        },
        '&:hover': {
          backgroundColor: 'transparent',
          color: colors.gray[400],
        },
      },
    },
    MuiChip: {
      icon: {
        color: 'inherit',
        marginLeft: '.5em',
        width: '.94em',
      },
      label: {
        paddingLeft: '.75em',
        paddingRight: '.5em',
      },
      root: {
        backgroundColor: colors.green[300],
        borderRadius: '.38em',
        color: colors.green[400],
        fontSize: '1em',
        fontWeight: 500,
      },
    },
    MuiContainer: {
      root: {
        padding: '0',
      },
    },
    MuiDialog: {
      paperWidthSm: {
        maxWidth: '500px',
        width: '100%',
      },
    },
    MuiDialogActions: {
      root: {
        flexDirection: 'column',
        padding: '0 40px 40px',
      },
      spacing: {
        marginLeft: 0,
        '& > :not(:first-child)': {
          marginLeft: 0,
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '40px 40px 20px',
        marginBottom: '40px',

        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        fontSize: '1em',
        padding: '0',
      },
    },
    MuiInput: {
      root: {
        border: `1px solid ${colors.gray[500]}`,
        borderRadius: '4px',
        padding: '.8rem 1rem',
        color: colors.black[100],
        boxShadow: 'inset 0 2px 6px 0 rgba(225, 228, 231, 0.67)',
      },
    },
    MuiInputLabel: {
      root: {
        fontSize: '1.3em',
        fontWeight: 500,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
      },
    },
    MuiMobileStepper: {
      root: {
        backgroundColor: 'transparent',
      },
      dot: {
        margin: '0 4px',
      },
      dotActive: {
        backgroundColor: colors.blue[700],
      },
    },
    MuiPaper: {
      outlined: {
        border: `1px solid ${colors.gray.b600}`,
        borderRadius: '.38em',
        marginTop: '1.5em',
        padding: '2em',
        [breakpoints.down('xs')]: {
          borderRadius: '0',
        },
      },
    },
    MuiStep: {
      completed: {
        color: colors.blue[500],
      },
      root: {
        color: colors.blue[200],
        marginTop: '1.65em',
        [breakpoints.down('xs')]: {
          marginTop: '0',
          marginLeft: '2.1em',
        },
      },
    },
    MuiStepConnector: {
      active: {
        borderColor: colors.blue[500],
      },
      completed: {
        borderColor: colors.blue[500],
      },
      horizontal: {
        marginTop: '2.15em',
      },
      line: {
        borderColor: 'inherit',
      },
      lineHorizontal: {
        borderTopWidth: '.5em',
      },
      lineVertical: {
        borderLeftWidth: '.5em',
        height: '3em',
      },
      root: {
        borderColor: colors.blue[200],
        display: 'none',
      },
      vertical: {
        [breakpoints.down('xs')]: {
          marginLeft: '2.8em',
          padding: '0',
        },
      },
    },
    MuiStepLabel: {
      iconContainer: {
        color: 'inherit',
        height: '1.5em',
        paddingRight: '0',
        width: '1.5em',
        zIndex: 1,
        [breakpoints.down('xs')]: {
          height: '2em',
          width: '2em',
        },
      },
      label: {
        color: colors.blue[200],
        fontWeight: 500,
        [breakpoints.down('xs')]: {
          marginLeft: '2.3em',
          position: 'relative',
        },
      },
      labelContainer: {
        [breakpoints.down('xs')]: {
          marginTop: '1em',
        },
      },
      root: {
        color: 'inherit',
        flexDirection: 'column',
        textAlign: 'center',
        [breakpoints.down('xs')]: {
          flexDirection: 'row',
          textAlign: 'left',
        },
      },
    },
    MuiStepper: {
      horizontal: {
        alignItems: 'flex-start',
      },
      root: {
        justifyContent: 'center',
        marginTop: '1.8em',
        padding: '0',
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '1em',
      },
    },
    MuiTab: {
      root: {
        backgroundColor: colors.gray.c200,
        borderBottom: 'none',
        borderRadius: '6px 6px 0 0',
        height: '4em',
        margin: '0 1.5px',
        padding: '0',
        '&$selected': {
          backgroundColor: 'white',
          border: `1px solid ${colors.gray.b600}`,
          color: colors.blue[700],
        },
        '&:first-child': {
          marginLeft: '0',
        },
        '&:hover': {
          backgroundColor: colors.gray[600],
        },
        '&:last-child': {
          marginRight: '0',
        },
        [breakpoints.up('xs')]: {
          minWidth: '0',
        },
      },
      wrapper: {
        fontSize: '1em',
        fontWeight: 500,
        textTransform: 'capitalize',
      },
    },
    MuiTabs: {
      indicator: {
        display: 'none',
      },
      root: {
        marginBottom: '-1px',
      },
    },
    MuiTooltip: {
      arrow: {
        color: colors.blue[700],
      },
      tooltip: {
        backgroundColor: colors.blue[700],
        fontSize: '.88em',
        padding: '.57em 1.21em',
      },
      tooltipPlacementBottom: {
        marginTop: '0',
        paddingTop: '.5em',
      },
    },
  },
  props: {
    MuiDialog: {
      PaperProps: {
        elevation: 0,
      },
    },
    MuiInput: {
      disableUnderline: true,
    },
    MuiInputLabel: {
      // This completely disables the animated moving labels and gives you
      // a natural form label look!
      disableAnimation: true,
      shrink: true,
    },
    MuiList: {
      disablePadding: true,
    },
    MuiPaper: {
      elevation: 4,
    },
  },
})
